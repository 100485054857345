import React from 'reactn';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Button,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Dialog,
  Grid,
  Menu,
  MenuItem,
  Zoom,
  CircularProgress,
  Box,
  DialogContent,
} from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import {
  USER_CONTEXT,
  LOCATIONS,
  CONTENT_AREA,
  ASSIGNMENT_TYPE,
  MANAGE_BALANCE_TYPE,
  CONTENT_AREAS_ENUM,
  USER_CARD_DATETIME_FORMAT,
  setRelationshipData,
  COMMUNICATION_TAB_TYPE,
  setJanusProfileToken,
} from '../../../constants';
import {
  getUserResources,
  getStudentProfile,
  getPathFromLocation,
  addBalance,
  deductBalance,
  getLocationFeatureStatus,
  getLearnerToken,
  getUserAttributes,
} from '../../../util/APIUtils';
import CloseIcon from '@material-ui/icons/Close';
import CreateIcon from '@material-ui/icons/Create';
import CourseDetails from './CourseDetails';
import styles from './styles';
import { withStyles } from '@material-ui/styles';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import moment from 'moment';
import TranscriptDialog from './Transcript';
import Notes from './notes/notes';
import PathAssginmentToUser from '../../PathAssginmentToUser';
import EditUser from './edit-user/edit-user';
import { Settings } from '../../management';
import { ManagementService } from '../../management/management.service';
import SettingsIcon from '@material-ui/icons/Settings';
import ManageBalance from '../../manage-balance/manage-balance';
import ResponseBox from '../../response-box';
import { CustomChart } from '../../chart';
import BulkUserCard from '../../bulk-user-card/bulk-user-card';
import StudentTransaction from '../../student-transaction/student-transaction';
import { UserCommunications as UserCommunication } from '../../user-communication';
import Relationships from './relationships/';
import StarsIcon from '@material-ui/icons/Stars';
import ViewFormsDialog from './ViewFormsDialog';
import { isPermitted, PermissionControl } from '../../permission-control/permission-control';
import ExploreListing from './explore/explore';
import TimeOnTask from './time-on-task/time-on-task';
import Programs from './programs/';
import { Insights } from '../../../v2/modules/learner-profile';
import { withUnleash } from '../../../v2/core/unleash';
import { UnleashService } from '../../../v2/services';
import { CustomIcons, icons } from '../../../v2/shared/components/custom-icons';
import { CustomTheme } from '@orijinworks/frontend-commons';
import { Transcript } from '../../../v2';
import { useEffect } from 'react';
import useUnleashHook from '../../../v2/core/unleash/useUnleashHook';
import LocationHistory from './location-history';
import { LearnerTabs } from './learner-tabs/learner-tabs';

//STYLE CONSTANTS
const DATE = Date.now();

class Learner extends React.Component {
  _managementService = new ManagementService();
  isCompletionReportFlagEnabled = true;
  TABS = {
    Insights: { index: 0, displayText: 'Insights' },
    LocationHistory: { index: 1, displayText: 'Location history' },
    Programs: { index: 2, displayText: 'Programs' },
    Explore: { index: 3, displayText: 'Explore' },
    Communication: { index: 4, displayText: 'Communication' },
    Relationships: { index: 5, displayText: 'Relationships' },
    ProgressReport: { index: 6, displayText: this.isCompletionReportFlagEnabled ? 'Completion Report' : 'Progress Report' },
    Forms: { index: 7, displayText: 'Forms' },
    TimeOnTask: { index: 8, displayText: 'Time on Task' },
  };

  _chart = new CustomChart();
  contentAreaRef = null;
  cacheDateRange = 7;
  loadingCntr = 0;
  lastActiveTab = this.TABS.Insights.index; // INSTANCE TO HANDLE LAST ACTIVE TAB WHEN POP-UP IS OPENED
  state = {
    userAttributes: [],
    featureStatusAtFacility: {
      Play: false,
      Messaging_Person: false,
    },
    features: null,
    incentiveEngineVisibile: false,
    totDetailsDialog: {
      isOpen: false,
    },
    activeTab: this.TABS.Insights.index,
    isCustomRange: false,
    dateRangePicker: {
      isOpen: false,
    },
    studentTransaction: {
      isOpen: false,
    },
    graphData: {
      locations: [],
      selectedLocation: '',
    },
    response: {
      isOpen: false,
      data: [],
      heading: '',
    },
    isSettingsOpen: false,
    isPathAssignementOpen: false,
    isViewFormsDialogOpen: false,
    assignementPaths: [],
    totIsLoading: false,
    totDataFetched: null,
    achievementDataFetched: false,
    achievementLoading: false,
    tokens: 0,
    hours: 0,
    tasks: 0,
    tokenBalance: 0,
    userResources: null,
    userResourceLoading: false,
    user: null,
    userID: null,
    expanded: null,
    entertainment: true,
    incentiveengine: false,
    access: true,
    selected: null,
    value: 0,
    filterDays: this.global.selectedDateRange,
    selectedView: 'summary',
    selectedTab: 'education',
    openSelectTimeMenu: false,
    openRangePicker: false,
    chartData: [],
    linechartData: [],
    averageData: [],
    'GED Language Arts': 99,
    openEditModal: false,
    showLocations: false,
    showTranscript: false,
    showNotesDialog: false,
    isUserCardOpen: false,
    manageBalance: {
      isOpen: false,
    },
  };

  get ctaList() {
    const Review_Submission_User_Profile = isPermitted(
      this.global.cbacConfigFlag,
      this.global.permissions,
      'Review_Submission_User_Profile'
    );

    const buttons = [];
    if (
      this.state.incentiveEngineVisibile &&
      isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Audit_Token_History')
    ) {
      buttons.push({
        text: 'View Learner Transaction',
        onClick: () => this.handleTransactionDialog(true),
      });
    }
    buttons.splice(1, 0, {
      text: 'View Additional Content',
      onClick: () => this.handleProgressByContentAreas(true),
    });
    buttons.push({
      text: 'View Time on Task Details',
      onClick: () => this.handleToTDetailsDialog(true),
    });
    return buttons;
  }
  getTabByIndex(index) {
    for (const key in this.TABS) {
      if (this.TABS.hasOwnProperty(key)) {
        if (this.TABS[key].index === index) {
          return this.TABS[key];
        }
      }
    }
    return null; // Return null if no object with the given index is found
  }

  handleViewFormsDialog = (isViewFormsDialogOpen) => {
    const state = {
      isViewFormsDialogOpen,
    };
    if (!isViewFormsDialogOpen) {
      state['activeTab'] = this.lastActiveTab;
    }
    this.setState(state);
  };

  /**
   * @name handleToTDetailsDialog
   * @param {boolean} isOpen
   * @desc Handles time on task dialog open flag.
   * @return {void}
   */

  handleToTDetailsDialog = (isOpen) =>
    this.setState({
      totDetailsDialog: {
        ...this.state.totDetailsDialog,
        isOpen,
      },
    });

  /**
   * @name getFeatures
   * @param {number} locationId
   * @desc Fetches features for specified location.
   * @return {void}
   */
  getFeatures = async (locationId) => {
    try {
      const feature = await getLocationFeatureStatus(locationId, 'Incentive_Engine');
      this.setState({
        incentiveEngineVisibile: feature.status,
      });
    } catch (e) {
      console.error(e);
    }
  };

  handleTransactionDialog = (isOpen) => {
    const state = {
      studentTransaction: {
        ...this.state.studentTransaction,
        isOpen,
      },
    };
    if (!isOpen) {
      state['activeTab'] = this.lastActiveTab;
    }
    this.setState(state);
  };

  handleUserCard = (isUserCardOpen) => {
    const state = {
      isUserCardOpen,
    };
    if (!isUserCardOpen) {
      state['activeTab'] = this.lastActiveTab;
    }
    this.setState(state);
  };

  fetchAttributes = (userId) => {
    return getUserAttributes(userId)
      .then((result) => {
        const userAttributes = result.personAtrributes || [];
        this.setState({
          userAttributes,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  handleSettingsDialog = async (isSettingsOpen) => {
    const state = {
      isSettingsOpen,
    };
    if (isSettingsOpen) {
      this.setGlobal({
        isLoading: true,
      });
      const userActiveLocation = this.getActiveLocation(this.state.user.userLocations);
      if (userActiveLocation) {
        const location = LOCATIONS.find((location) => location.locationId === userActiveLocation.locationId);
        let idsToCheck = [];
        if (location.locationTypeId === 15) {
          idsToCheck.push(location.locationId);
          idsToCheck.push(location.parentLocationId);
        } else if (location.locationTypeId === 3) {
          idsToCheck.push(location.locationId);
        }
        const playPromises = idsToCheck.map((locationId) => getLocationFeatureStatus(locationId, 'Play'));
        const messagingPersonPromises = idsToCheck.map((locationId) =>
          getLocationFeatureStatus(locationId, 'Messaging_Person')
        );
        const playLog = await Promise.all(playPromises);
        const msgLog = await Promise.all(messagingPersonPromises);
        state['featureStatusAtFacility'] = {
          Play: playLog.every((_successLog) => _successLog.status),
          Messaging_Person: msgLog.every((_successLog) => _successLog.status),
        };
      }
      await this.fetchAttributes(this.state.user.userDetail.userId);
      this.setGlobal({
        isLoading: false,
      });
    }
    this.setState(state);
  };

  setHiddenBreadcrumbItems = (tabID) => {
    this.setGlobal({
      hiddenBreadcrumbItems: ['Learner Profile', this.getTabByIndex(tabID).displayText],
    });
  };

  /**
   * @name changeTabHandler
   * @param {number} tabID
   * @desc Hanldes tab changes event.
   * @return {void}
   */
  changeTabHandler = (tabID) => {
    this.lastActiveTab = this.state.activeTab;
    this.setState({
      activeTab: tabID,
    });
    this.setHiddenBreadcrumbItems(tabID);
  };

  /**
   * @name getColumnChartColors
   * @desc Returns column chart colors
   * @return {array}
   */
  get getColumnChartColors() {
    return [
      CustomTheme.palette.primary.main, // TOTAL COLOR
      CONTENT_AREA[CONTENT_AREAS_ENUM.Education].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Rehab].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Workforce].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.ReEntry].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Enrichment].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Entertainment].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Misc].bgColor,
      CONTENT_AREA[CONTENT_AREAS_ENUM.Communication].bgColor,
    ];
  }

  /**
   * parse id from url path
   */
  parseUserFromURL = () => {
    const path = window.location.href;
    if (path) {
      const regex = /learner\/(\d+)/;
      const match = path.match(regex);

      if (match) {
        this.setGlobal({
          selectedUser: match[1],
        });
      }
    }
  };

  updateUser = (data) => {
    this.setState({
      user: {
        ...this.state.user,
        userDetail: {
          ...this.state.user.userDetail,
          ...data,
        },
      },
    });
    if (this.global.fetchUsers) {
      this.global.fetchUsers();
    }
  };

  /**
   * @name closePathAssignementHandler
   * @param {boolean} isOperationDone
   * @desc Handler fired when path assignement modal is being closed.
   * @return {void}
   */
  closePathAssignementHandler = (isOperationDone) => {
    this.setState({ isPathAssignementOpen: false });
    if (isOperationDone) {
      this.getUserResources(this.state.user.userDetail.userId, this.contentAreaRef.getSelectedContentAreas());
    }
  };

  /**
   * @name getPathByUserLocation
   * @param {number} locationId
   * @desc Fetches all path that are assigned to learners active location.
   * @return {void}
   */
  getPathByUserLocation = (locationId) => {
    if (this.state.assignementPaths.length > 0) {
      this.setState({
        isPathAssignementOpen: true,
      });
      return;
    }

    this.setGlobal({
      isLoading: true,
    });
    getPathFromLocation(locationId).then((_successLog) => {
      this.setState({
        assignementPaths: _successLog,
        isPathAssignementOpen: true,
      });
      this.setGlobal({
        isLoading: false,
      });
    });
  };

  /**
   * @name userAssignmentHandler
   * @param {object} activeLocation User's active location.
   * @desc Handler fired when clicked on assigne button.
   * @return {void}
   */
  userAssignmentHandler = (activeLocation) => {
    this.getPathByUserLocation(activeLocation.locationId);
  };

  /**
   * @name getUserResources
   * @param {number} userId
   * @param {array} contentAreas
   * @desc Fetches user resources.
   * @warning Updates state
   * @return {void}
   */
  getUserResources = (userId, contentAreas) => {
    this.setState({
      userResourceLoading: true,
    });
    this.toggleLoader('show');
    getUserResources(userId, contentAreas.join(',')).then(
      (_successLog) => {
        this.setState({
          userResources: _successLog.filter((resource) => resource.active),
          inactiveResources: _successLog.filter((resource) => !resource.active),
          userResourceLoading: false,
        });
        this.toggleLoader('hide');
      },
      (_errorLog) => {
        this.toggleLoader('hide');
      }
    );
  };

  getUserData = (isFetchNew) => {
    const { userResources, userResourceLoading, achievementDataFetched, achievementLoading, totDataFetched, totIsLoading } =
      this.state;
    if (this.contentAreaRef) {
      if ((!userResources && !userResourceLoading) || isFetchNew) {
        this.getUserResources(this.state.user.userDetail.userId, this.contentAreaRef.getSelectedContentAreas());
      }
    }
    if ((!achievementDataFetched && !achievementLoading) || isFetchNew) {
      this.getLearnerToken(this.state.user.userDetail.userId);
    }
  };

  componentDidUpdate(nextProps, nextState) {
    const { user } = this.state;
    if (user && this.state.qryEtag) {
      const isEtagChanged = this.state.qryEtag !== nextState.qryEtag;
      this.getUserData(isEtagChanged);
    }
    if (this.state.user && this.state.user.userDetail.userId != this.global.selectedUser && !this.state.loading) {
      this.getStudentInformation(this.global.selectedUser);
    }
    this.setHiddenBreadcrumbItems(this.state.activeTab);
  }

  componentDidMount() {
    this.parseUserFromURL();
    this.getStudentInformation(this.global.selectedUser);
    this.setHiddenBreadcrumbItems(this.state.activeTab);
  }

  componentWillUnmount() {
    setJanusProfileToken('');
    setRelationshipData(null);
    this.setGlobal({ selectedUser: null });
  }

  toggleLoader = (type) => {
    if (type === 'show') {
      this.loadingCntr++;
    } else if (type === 'hide') {
      this.loadingCntr--;
    }

    // GLOBAL LOADER
    if (this.loadingCntr > 0 && !this.global.isLoading) {
      this.setGlobal({
        isLoading: true,
      });
    } else if (this.loadingCntr === 0 && this.global.isLoading) {
      this.setGlobal({
        isLoading: false,
      });
    }
  };

  /**
   * @name getLearnerToken
   * @desc Fetches achievement data from server for user.
   * @return {void}
   */
  getLearnerToken = (userId) => {
    this.toggleLoader('show');
    this.setState({
      achievementLoading: true,
    });
    Promise.all([getLearnerToken(userId)]).then(
      (_successLog) => {
        this.setState({
          tokenBalance: _successLog[0].balance,
          achievementLoading: false,
          achievementDataFetched: true,
        });
        this.toggleLoader('hide');
      },
      (_errorLog) => {
        this.toggleLoader('hide');
      }
    );
  };

  createDataTree = (data) => {
    let hashTable = Object.create(null);
    data.forEach(
      (aData) =>
        (hashTable[aData.resourceKey] = {
          ...aData,
          childNodes: {
            items: [],
            folders: [],
          },
          itemsOverdue: [],
          totalAssessmentItems: 0,
          completedAssessmentItems: 0,
        })
    );
    let dataTree = [];
    data.forEach((aData) => {
      if (aData.parentResourceKey !== null && hashTable[aData.parentResourceKey]) {
        if (aData.itemType === 'Folder') {
          hashTable[aData.parentResourceKey].childNodes.folders.push(hashTable[aData.resourceKey]);
        } else {
          hashTable[aData.parentResourceKey].childNodes.items.push(hashTable[aData.resourceKey]);
        }
      } else dataTree.push(hashTable[aData.resourceKey]);
    });

    var newTree = dataTree.map((course) => {
      //parses course folders
      course.childNodes.items.map((i) => {
        if (i.itemType === 'Assessment') {
          course.totalAssessmentItems += 1;
        }
        if (i.itemType === 'Assessment' && i.completed) {
          course.completedAssessmentItems += 1;
        }
        if (i.dueDate !== null && i.itemType === 'Assessment') {
          var idueDate = Date.parse(i.dueDate);
          var checkDueDate = DATE - idueDate;
          i.dueDate = i.dueDate.substring(0, 10);
          if (checkDueDate > 0 && !i.completed) {
            course.itemsOverdue.push(i);
          }
        }
      });
      course.childNodes.folders.map((cFolders) => {
        //parse items in course items
        cFolders.childNodes.items.map((i1) => {
          if (i1.itemType === 'Assessment') {
            course.totalAssessmentItems += 1;
            cFolders.totalAssessmentItems += 1;
          }
          if (i1.itemType === 'Assessment' && i1.completed) {
            course.completedAssessmentItems += 1;
            cFolders.completedAssessmentItems += 1;
          }
          if (i1.dueDate !== null && i1.itemType === 'Assessment') {
            var i1dueDate = Date.parse(i1.dueDate);
            var checkDueDate = DATE - i1dueDate;
            i1.dueDate = i1.dueDate.substring(0, 10);
            if (checkDueDate > 0 && !i1.completed) {
              course.itemsOverdue.push(i1);
              cFolders.itemsOverdue.push(i1);
            }
          }
        });
        //parse folders for childnodes
        cFolders.childNodes.folders.map((f1) => {
          f1.childNodes.items.map((i2) => {
            if (i2.itemType === 'Assessment') {
              course.totalAssessmentItems += 1;
              cFolders.totalAssessmentItems += 1;
              f1.totalAssessmentItems += 1;
            }
            if (i2.itemType === 'Assessment' && i2.completed) {
              course.completedAssessmentItems += 1;
              cFolders.completedAssessmentItems += 1;
              f1.completedAssessmentItems += 1;
            }
            if (i2.dueDate !== null && i2.itemType === 'Assessment') {
              var i2dueDate = Date.parse(i2.dueDate);
              var checkDueDate = DATE - i2dueDate;
              i2.dueDate = i2.dueDate.substring(0, 10);
              if (checkDueDate > 0 && !i2.completed) {
                course.itemsOverdue.push(i2);
                cFolders.itemsOverdue.push(i2);
                f1.itemsOverdue.push(i2);
              }
            }
          });
          f1.childNodes.folders.map((f2) => {
            f2.childNodes.items.map((i3) => {
              if (i3.itemType === 'Assessment') {
                course.totalAssessmentItems += 1;
                cFolders.totalAssessmentItems += 1;
                f1.totalAssessmentItems += 1;
                f2.totalAssessmentItems += 1;
              }
              if (i3.itemType === 'Assessment' && i3.completed) {
                course.completedAssessmentItems += 1;
                cFolders.completedAssessmentItems += 1;
                f1.completedAssessmentItems += 1;
                f2.completedAssessmentItems += 1;
              }
              if (i3.dueDate !== null && i3.itemType === 'Assessment') {
                var i3dueDate = Date.parse(i3.dueDate);
                var checkDueDate = DATE - i3dueDate;
                i3.dueDate = i3.dueDate.substring(0, 10);
                if (checkDueDate > 0 && !i3.completed) {
                  course.itemsOverdue.push(i3);
                  cFolders.itemsOverdue.push(i3);
                  f1.itemsOverdue.push(i3);
                  f2.itemsOverdue.push(i3);
                }
              }
            });
          });
        });
      });
      return course;
    });

    // consolelog("RETURNING THE TREE TO YOU:", newTree)
    return newTree;
  };

  generateCourseCards(data) {
    //// consolelog("generating data for paxen", data)
    var myTree = this.createDataTree(data);
    this.setState({
      paxenData: myTree,
    });
  }

  generatePaxenChart(data) {
    var _data = data;
    let unique_values = [...new Set(_data.map((item) => item.entity_title))];
    //// consolelog("UNIQUE VALUES", unique_values)
    var chartData = [];
    unique_values.map((u) => {
      var array = [u];
      var activityCount = 0;
      var activityTotal = 0;
      var average = 0;
      data.map((a) => {
        if (a.entity_title === u) {
          activityCount++;
          activityTotal += a.grades_achieved;
        }
      });
      average = (activityTotal / activityCount).toFixed(2);
      array.push(average);
      chartData.push(array);
    });
    //console.log("PAXEN CHART DATA", chartData)
    this.setState({ paxenChart: chartData });
  }

  /**
   * @name getActiveLocation
   * @param {array} userLocations
   * @desc Get's active location from array otherwise returns null.
   * @return {boolean}
   */
  getActiveLocation = (userLocations) => {
    const activeLocation = userLocations.filter((location) => location.active);
    if (activeLocation.length > 0) {
      return activeLocation[0];
    }
    return null;
  };

  /**
   * @name getStudentInformation
   * @param {number} personId
   * @desc Fetches student information.
   * @return {void}
   */
  getStudentInformation = (personId) => {
    this.toggleLoader('show');
    this.setState({
      loading: true,
    });
    getStudentProfile(personId).then(
      (_successLog) => {
        this.setState({
          user: {
            userDetail: {
              ..._successLog.userDetail,
              active: _successLog.userDetail.active ? 'active' : 'inactive',
            },
            userLocations: _successLog.userLocations,
            userServices: _successLog.userServices,
          },
          loading: false,
          qryEtag: Math.random(),
        });
        const activeLocation = this.getActiveLocation(_successLog.userLocations);
        if (activeLocation) {
          const locationId = activeLocation.locationId;
          this.getFeatures(locationId);
          this.setState({ userActiveLocation: locationId });
          this.setGlobal({ selectedLearnerLocationId: locationId });
        }
        this.toggleLoader('hide');
      },
      (_errorLog) => {
        this.setGlobal({ selectedUser: null, openProfileView: false });
        this.toggleLoader('hide');
        console.error('Something went wrong, please check network tabs for more information.', _errorLog.response);
      }
    );
  };

  populateTables(results) {
    this.setState({
      Education: null,
      Entertainment: null,
      Rehabilitation: null,
      'Workforce skills': null,
      Enrichment: null,
      Miscellaneous: null,
      Reentry: null,
    });
    results.map((c) => {
      var name = c.contentAreaName;
      c.learnerResults.map((r) => {
        if (r.completed === true) {
          r.completed = 'True';
        } else {
          r.completed = 'In Progress';
        }
      });
      this.setState({ [name]: c.learnerResults });
      // consolelog("Learner results content",c.contentAreaName, c.learnerResults, this.state[c.contentAreaName])
    });
  }

  ///Handlers

  handleTabs = (event, value) => {
    this.setState({ value });
  };

  handleTranscriptDialog = (isDialogVisible) => {
    this.setState({
      showTranscript: isDialogVisible,
      activeTab: !isDialogVisible ? this.lastActiveTab : this.state.activeTab, // ONLY UPDATE IF ITS CLOSING
    });
  };

  handleEditDialog = (isDialogVisible) => {
    this.setState({
      showEditDialog: isDialogVisible,
    });
  };

  handleNotesDialog = (isDialogVisible) => {
    this.setState({
      showNotesDialog: isDialogVisible,
    });
  };

  handleProgressByContentAreas = (isDialogVisible) => {
    this.setState({
      showProgressByContentAreas: isDialogVisible,
    });
  };

  handleChangePanel = (panel) => (event, expanded) => {
    this.setState({
      expanded: expanded ? panel : false,
    });
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
    //// consolelog('daterange:', this.state.dateRange)
  };

  handleSwitch = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSelect = (value) => (event) => {
    this.setState({
      selected: value,
    });
  };

  openRangePicker() {
    this.setState({ openRangePicker: true });
  }

  closeRangePicker() {
    this.setState({ openRangePicker: false });
  }

  changeFilterDate = (value, range) => {
    this.cacheDateRange = value;
    if (!range) {
      var d = new Date();
      d.setDate(d.getDate() - value);
      range = {
        startDate: d.toISOString(),
        endDate: new Date().toISOString(),
      };
    }
    this.setState({
      qryEtag: Math.random(),
      isCustomRange: false,
    });
    const globalState = {
      selectedDateRange: value,
    };

    globalState['range'] = range;
    this.setGlobal(globalState, () => {
      this.getUserData();
    });
  };

  closeProfileView = () => {
    this.setGlobal({
      openProfileView: false,
      selectedUser: null,
      fetchUsers: null,
    });
  };

  /**
   * @name getTotalResourceProgress
   * @param {array} resources
   * @desc Calculates total resource progress.
   * @return {void}
   */
  getTotalResourceProgress = (resources) => {
    const data = {
      completedItems: 0,
      totalItems: 0,
    };
    resources.forEach((resource) => {
      data['completedItems'] += resource.completedScore;
      data['totalItems'] += resource.totalScore;
    });
    return data;
  };

  openEditModal = () => {
    this.setState({
      openEditModal: true,
    });
  };

  handleResponseDialog = (isOpen) => {
    this.setState({
      response: {
        ...this.state.response,
        isOpen,
      },
    });
  };

  /**
   * @name getResponseBoxData
   * @param {array} successList
   * @param {array} errorList
   * @desc Prepares get response box data and returns results.
   * @return {array} responseData
   */
  getResponseBoxData = (successList, errorList) => {
    const responseData = [];
    const type = this.state.manageBalance.type;
    successList.forEach((_successNodes) => {
      responseData.push({
        statusCode: _successNodes.statusCode,
        text: `${_successNodes.transactionAmount} has been ${
          type === MANAGE_BALANCE_TYPE.ADD ? 'added to ' : 'deducted from '
        } ${_successNodes.userName}'s account.`,
      });
    });
    errorList.forEach((_errorNodes) => {
      responseData.push({
        statusCode: _errorNodes.statusCode,
        text: `${_errorNodes.statusMessage}.`,
      });
    });
    return responseData;
  };

  /**
   * @name submitManageBalance
   * @param {array} data
   * @desc Submits manage balance and check's in state whether its add / deduct balance process.
   * @return {void}
   */
  submitManageBalance = (data) => {
    const payload = {
      transactions: data.map((userBalanceMeta) => ({
        personId: userBalanceMeta.user.userId,
        featureName: null,
        transactionAmount: userBalanceMeta.amount,
        reason: userBalanceMeta.reason,
      })),
    };

    let requestHandler = null;
    this.setGlobal({
      isLoading: true,
    });
    const type = this.state.manageBalance.type;
    if (type === MANAGE_BALANCE_TYPE.ADD) {
      requestHandler = addBalance(payload);
    } else if (type === MANAGE_BALANCE_TYPE.DEDUCT) {
      requestHandler = deductBalance(payload);
    }
    requestHandler.then(
      (_successLog) => {
        this.setGlobal({
          isLoading: false,
        });
        this.setState({
          manageBalance: {
            ...this.state.manageBalance,
            isOpen: false,
          },
          response: {
            isOpen: true,
            data: this.getResponseBoxData(_successLog.successList, _successLog.errorList),
            heading: type === MANAGE_BALANCE_TYPE.ADD ? 'Add Balance' : 'Deduct Balance',
          },
        });
      },
      (_errorLog) => {
        this.setState({
          manageBalance: {
            ...this.state.manageBalance,
            isOpen: false,
          },
        });
        this.setGlobal({
          isLoading: false,
        });
        console.error('Something went wrong while adding/deducting balance, please check network.');
      }
    );
  };

  getDistinctLocations = (locations = []) => {
    const distinctLocation = [];
    locations.forEach((location) => {
      const locationExist = distinctLocation.some((foundLocation) => location.locationId === foundLocation.locationId);
      if (!locationExist) {
        distinctLocation.push(location);
      }
    });
    return distinctLocation;
  };

  showLocations = () => {
    this.setState({ showLocations: true });
  };

  closeLocations = () => {
    this.setState({ showLocations: false });
  };

  closeEditModal = () => {
    this.setState({
      openEditModal: false,
    });
  };

  manageBalanceDialog = (isOpen) => {
    this.setState({
      manageBalance: {
        ...this.state.manageBalance,
        isOpen,
      },
    });
  };

  showCourseDetails(course) {
    this.setState({
      selectedCourseDetails: course,
      showCourseDetails: true,
    });
    //// consolelog("CourseDetailsOpen:", course)
  }

  closeCourseDetails = () => {
    this.setState({
      showCourseDetails: false,
      selectedCourseDetails: null,
    });
  };

  handleAttachMoneyMenu = (attachMoneyAnchorElement) => {
    this.setState({
      attachMoneyAnchorElement,
    });
  };
  ///End of Handlers

  handleManageBalanceMeta = (data) => {
    this.setState({
      manageBalance: {
        ...this.state.manageBalance,
        ...data,
      },
    });
  };

  handleDateRangePicker = (isOpen) => {
    this.setState({
      dateRangePicker: {
        ...this.state.dateRangePicker,
        isOpen,
      },
    });
  };

  handleDateRangeChange = (range) => {
    this.setState(
      {
        qryEtag: Math.random(),
      },
      () => {
        this.getUserData();
      }
    );
  };

  get isSuperAdmin() {
    return this.global.isSuperAdmin;
  }

  DummyComponentForCompletionReportFeatureFlag = () => {
    const isFlagEnabled = useUnleashHook(UnleashService.FLAGS.COMPLETION_REPORT).isFlagEnabled();
    useEffect(() => {
      this.isCompletionReportFlagEnabled = isFlagEnabled;
    }, [isFlagEnabled]);
    return null;
  };

  render() {
    const { classes } = this.props;
    const { activeTab } = this.state;
    const userActiveLocation = this.state.user
      ? this.state.user.userLocations.find((location) => location.locationId === this.global.selectedLearnerLocationId)
      : null;
    return (
      <Box
        classes={{
          paper: [classes.paper, 'scroller-view'].join(' '),
          root: [classes.dialogRoot, classes.mainDialogContainer].join(' '),
        }}
        PaperProps={{
          elevation: 1,
        }}
        open={this.global.openProfileView && this.global.selectedUser}
        onClose={this.closeProfileView}
      >
        <this.DummyComponentForCompletionReportFeatureFlag /> {/* TODO: REMOVE WITH FEATURE FLAG CLEANUP */}
        {this.state.user && userActiveLocation && (
          <PathAssginmentToUser
            type={ASSIGNMENT_TYPE.ASSIGNE}
            open={this.state.isPathAssignementOpen}
            onClose={this.closePathAssignementHandler}
            locationId={userActiveLocation.locationId}
            paths={this.state.assignementPaths}
            selectedUser={[
              {
                ...this.state.user.userDetail,
                personId: parseInt(this.state.user.userDetail.userId),
              },
            ]}
            showSubHeader={true}
            deletedUsers={[]}
          />
        )}
        {this.state.loading === false ? (
          <div style={{ margin: '0rem 1rem 8rem -2rem' }}>
            <Grid container spacing={24}>
              <Grid item md={12}>
                <Paper className={classes.userProfileWrapper}>
                  <Grid container item md={12}>
                    <Grid container alignItems="center" item md={3}>
                      <Box component="div">
                        <CustomIcons
                          icon={icons.GraduationCap}
                          className={[
                            classes.userIcon,
                            this.state.user.userDetail.active === 'active' ? classes.colorDark : classes.colorLight,
                          ].join(' ')}
                        />
                      </Box>
                      <Box className={classes.userProfileInfoWrapper} component="div" display="flex" flexDirection="column">
                        <Box display="flex" alignItems="center">
                          <Typography className={classes.userProfileHeading}>
                            {`${this.state.user.userDetail.firstName} ${this.state.user.userDetail.lastName}`}
                          </Typography>
                        </Box>

                        <Typography className={classes.userId}>DOC ID: {this.state.user.userDetail.docId}</Typography>
                      </Box>
                    </Grid>

                    <Grid container alignItems="center" item md={5}>
                      <Grid container alignItems="center" justify="space-between" item md={6}>
                        <Box display="flex" flexDirection="column">
                          <Typography style={{ lineHeight: '46px' }} className={classes.metaMainText}>
                            {userActiveLocation && this.state.user.userDetail.active === 'active'
                              ? userActiveLocation.locationTypeId === 15
                                ? `${userActiveLocation.parentLocationName}, ${userActiveLocation.locationName}`
                                : userActiveLocation.locationName
                              : 'N/A'}
                          </Typography>
                          <Typography className={classes.metaSubText}>Location</Typography>
                        </Box>
                        {this.state.incentiveEngineVisibile && (
                          <Box display="flex" flexDirection="column">
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography id="learner-token-available-txt" className={classes.metaMainText}>
                                {this.state.tokenBalance}
                              </Typography>
                              <PermissionControl
                                permissionNames={['Grant_Tokens', 'Remove_Tokens']}
                                forceRestriction={!this.global.cbacConfigFlag && this.global.isAttorney}
                              >
                                <Box>
                                  <IconButton
                                    id="manage-token-btn"
                                    onClick={(event) => this.handleAttachMoneyMenu(event.currentTarget)}
                                    color="primary"
                                  >
                                    <StarsIcon />
                                  </IconButton>
                                  <Menu
                                    anchorEl={this.state.attachMoneyAnchorElement}
                                    keepMounted
                                    open={!!this.state.attachMoneyAnchorElement}
                                    onClose={() => this.handleAttachMoneyMenu(null)}
                                  >
                                    <PermissionControl permissionName="Grant_Tokens">
                                      <MenuItem
                                        id="add-token-btn"
                                        onClick={() => {
                                          this.handleAttachMoneyMenu(null);
                                          this.handleManageBalanceMeta({
                                            isOpen: true,
                                            title: 'Add Token',
                                            amountLabel: 'Amount to Add',
                                            reasonLabel: 'Reason',
                                            type: MANAGE_BALANCE_TYPE.ADD,
                                            formData: [
                                              {
                                                user: this.state.user.userDetail,
                                                amount: '',
                                                reason: '',
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        Add Token
                                      </MenuItem>
                                    </PermissionControl>
                                    <PermissionControl
                                      permissionName="Remove_Tokens"
                                      forceRestriction={!this.global.cbacConfigFlag && !this.global.isSuperAdmin}
                                    >
                                      <MenuItem
                                        id="deduct-token-btn"
                                        onClick={() => {
                                          this.handleAttachMoneyMenu(null);
                                          this.handleManageBalanceMeta({
                                            isOpen: true,
                                            title: 'Deduct Token',
                                            amountLabel: 'Amount to Deduct',
                                            reasonLabel: 'Reason',
                                            type: MANAGE_BALANCE_TYPE.DEDUCT,
                                            formData: [
                                              {
                                                user: this.state.user.userDetail,
                                                amount: '',
                                                reason: '',
                                              },
                                            ],
                                          });
                                        }}
                                      >
                                        Deduct Token
                                      </MenuItem>
                                      {this.state.incentiveEngineVisibile &&
                                        isPermitted(
                                          this.global.cbacConfigFlag,
                                          this.global.permissions,
                                          'Audit_Token_History'
                                        ) && (
                                          <MenuItem
                                            id="deduct-token-btn"
                                            onClick={() => {
                                              this.handleAttachMoneyMenu(null);
                                              this.handleTransactionDialog(true);
                                            }}
                                          >
                                            View Transactions
                                          </MenuItem>
                                        )}
                                    </PermissionControl>
                                  </Menu>
                                </Box>
                              </PermissionControl>
                            </Box>
                            <Typography className={classes.metaSubText}>Tokens</Typography>
                          </Box>
                        )}
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      justify="flex-end"
                      item
                      md={2}
                      className={classes.actionContainer}
                    >
                      <Box display="flex" flexDirection="row"></Box>
                      <PermissionControl permissionName="Edit_Learners_Information">
                        <Button
                          id="learner-profile-settings-btn"
                          onClick={() => this.handleEditDialog(true)}
                          className={classes.blueBtn}
                        >
                          <CreateIcon />
                          Edit
                        </Button>
                      </PermissionControl>
                      <PermissionControl permissionName="Assign_Path_To_Learner">
                        <Button
                          id="learner-profile-assign-btn"
                          onClick={() => this.handleUserCard(true)}
                          className={classes.blueBtn}
                        >
                          <CustomIcons icon={icons.KeyIcon} className={classes.KeyIcon} /> &nbsp; Learner Card
                        </Button>
                      </PermissionControl>
                    </Grid>

                    <Grid
                      container
                      direction="column"
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      item
                      md={2}
                      className={classes.actionContainer}
                    >
                      <Box display="flex" flexDirection="row"></Box>
                      <Button
                        id="learner-profile-settings-btn"
                        onClick={() => this.handleSettingsDialog(true)}
                        className={classes.blueBtn}
                      >
                        <SettingsIcon /> &nbsp; Settings
                      </Button>
                      <PermissionControl permissionName="Assign_Path_To_Learner">
                        <Button
                          id="learner-profile-assign-btn"
                          onClick={() => this.userAssignmentHandler(userActiveLocation)}
                          className={classes.blueBtn}
                        >
                          <AssignmentReturnIcon /> &nbsp; Assign
                        </Button>
                      </PermissionControl>
                    </Grid>
                  </Grid>
                </Paper>

                <Box marginBottom="20px">
                  <LearnerTabs 
                        classes={classes}
                        unleashProps={this.props.unleashProps}
                        activeTab={activeTab}
                        changeTabHandler={this.changeTabHandler}
                        global={this.global}
                        TABS={this.TABS}
                        handleTranscriptDialog={this.handleTranscriptDialog}
                        handleViewFormsDialog= {this.handleViewFormsDialog}
                        isCompletionReportFlagEnabled={this.isCompletionReportFlagEnabled}
                    />
                </Box>
              </Grid>
              {activeTab === this.TABS.ProgressReport.index && <Transcript user={this.state.user} />}
              {activeTab === this.TABS.TimeOnTask.index && (
                <TimeOnTask user={this.state.user} incentiveEngineVisibile={this.state.incentiveEngineVisibile} />
              )}
              {activeTab === this.TABS.Insights.index && <Insights user={this.state.user.userDetail} />}

              {activeTab === this.TABS.LocationHistory.index &&
                <LocationHistory
                  userLocations={this.getDistinctLocations(this.state.user.userLocations)}
                />
              }

              {activeTab === this.TABS.Explore.index && (
                <ExploreListing
                  setResponse={(response) =>
                    this.setState({
                      response,
                    })
                  }
                  userDetail={this.state.user.userDetail}
                  userLocation={userActiveLocation ? userActiveLocation : null}
                />
              )}
              {activeTab === this.TABS.Programs.index && userActiveLocation && (
                <Programs locationId={userActiveLocation.locationId} />
              )}
              {activeTab === this.TABS.Communication.index && (
                <UserCommunication
                  {...this.state.user}
                  userLocations={this.getDistinctLocations(this.state.user.userLocations)}
                  userActiveLocation={userActiveLocation}
                  type={COMMUNICATION_TAB_TYPE.Learner}
                  profileUser={this.state.user.userDetail}
                  showAnnouncements={true}
                />
              )}
              {activeTab === this.TABS.Relationships.index && (
                <Relationships
                  {...this.state.user}
                  setResponse={(response) =>
                    this.setState({
                      response,
                    })
                  }
                  profileUser={this.state.user.userDetail}
                />
              )}
            </Grid>
            <Dialog open={this.state.showLocations} fullScreen onClose={this.closeLocations} style={{ width: '100%' }}>
              <AppBar>
                <Toolbar>
                  <IconButton edge="start" color="inherit" onClick={this.closeLocations} aria-label="Close">
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6" style={{ flexGrow: 1, color: 'white' }}>
                    {`${this.state.user.userDetail.firstName} ${this.state.user.userDetail.lastName}`}'s Locations
                  </Typography>
                </Toolbar>
              </AppBar>
              <Paper style={{ marginTop: '5rem' }}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Parent Location</TableCell>
                      <TableCell align="right">Location Name</TableCell>
                      <TableCell align="right">Active</TableCell>
                      <TableCell align="right">Location ID</TableCell>
                      <TableCell align="right">Start Date</TableCell>
                      <TableCell align="right">End Date</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(this.state.user.userLocations || []).map((row) => (
                      <TableRow key={row.name}>
                        <TableCell scope="row">{row.locationName}</TableCell>
                        <TableCell align="right">{row.locationName}</TableCell>
                        <TableCell align="right">{row.active ? 'active' : 'false'}</TableCell>
                        <TableCell align="right">{row.locationId}</TableCell>
                        <TableCell align="right">{row.startDate}</TableCell>
                        <TableCell align="right">{row.endDate}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Paper>
            </Dialog>
          </div>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" padding="10px">
            <CircularProgress />
          </Box>
        )}
        <div>
          {this.state.user ? (
            <>
              <Zoom in unmountOnExit>
                <Paper style={{ marginTop: '2rem' }}>
                  <CourseDetails
                    user={this.state.user.userDetail}
                    course={this.state.selectedCourseDetails}
                    open={this.state.showCourseDetails}
                    close={() => this.setState({ showCourseDetails: false })}
                  />
                  <StudentTransaction
                    onClose={() => this.handleTransactionDialog(false)}
                    userDetail={this.state.user.userDetail}
                    {...this.state.studentTransaction}
                  />

                  <Notes open={this.state.showNotesDialog} closeHandler={this.handleNotesDialog} />

                  <ManageBalance
                    {...this.state.manageBalance}
                    onSubmit={(data) => this.submitManageBalance(data)}
                    onClose={() => this.manageBalanceDialog(false)}
                  />
                  {!this.isCompletionReportFlagEnabled && (
                    <TranscriptDialog
                      open={this.state.showTranscript}
                      closeHandler={this.handleTranscriptDialog}
                      userDetail={this.state.user.userDetail}
                    />
                  )}
                  <ResponseBox
                    heading={this.state.response.heading}
                    isOpen={this.state.response.isOpen}
                    onClose={() => {
                      this.handleResponseDialog(false);
                      this.getStudentInformation(this.global.selectedUser);
                    }}
                    data={this.state.response.data}
                    groupedView={false}
                  />

                  <ViewFormsDialog
                    isOpen={this.state.isViewFormsDialogOpen}
                    handleClose={() => this.handleViewFormsDialog(false)}
                    user={this.state.user.userDetail}
                  />
                </Paper>
              </Zoom>
            </>
          ) : null}
        </div>
        {this.state.user && this.state.user.userLocations ? (
          <EditUser
            updateUser={this.updateUser}
            requestorId={USER_CONTEXT.personId}
            requestorLocationId={
              this.state.user.userLocations.length > 0 ? this.state.user.userLocations[0].locationId : null
            }
            requestorLocationName={
              this.state.user.userLocations.length > 0 ? this.state.user.userLocations[0].locationName : null
            }
            location={this.state.user.userLocations.length > 0 ? this.state.user.userLocations[0] : {}}
            user={this.state.user.userDetail}
            open={this.state.showEditDialog}
            closeHandler={this.handleEditDialog}
            onUpdate={() => this.getStudentInformation(this.global.selectedUser)}
          />
        ) : null}
        {this.state.error ? (
          <div>
            <h3 style={{ textAlign: 'center' }}>Status Code: {this.state.error.status}</h3>
            <p style={{ textAlign: 'center' }}>Message: {this.state.error.message}</p>
          </div>
        ) : null}
        {this.state.user && (
          <div>
            <Dialog open={this.state.isSettingsOpen} fullScreen onClose={() => this.handleSettingsDialog(false)}>
              <AppBar
                style={{
                  position: 'relative',
                }}
              >
                <Toolbar>
                  <IconButton
                    onClick={() => this.handleSettingsDialog(false)}
                    edge="start"
                    color="inherit"
                    aria-label="close"
                  >
                    <CloseIcon />
                  </IconButton>
                  <Typography variant="h6">
                    Learner Settings{' '}
                    {this.state.user &&
                      `( ${this.state.user.userDetail.userName} | DOCID# ${this.state.user.userDetail.docId || 'N/A'})`}
                  </Typography>
                </Toolbar>
              </AppBar>
              <DialogContent>
                {this.state.user && (
                  <Settings
                    userAttributes={this.state.userAttributes}
                    features={[
                      ManagementService.MANAGEMENT_FEATURE_ENUM.Messaging,
                      ManagementService.MANAGEMENT_FEATURE_ENUM.FFMessaging,
                      ManagementService.MANAGEMENT_FEATURE_ENUM.Play,
                      ManagementService.MANAGEMENT_FEATURE_ENUM.UserDevicePairing,
                      ManagementService.MANAGEMENT_FEATURE_ENUM.VoiceCalls,
                      ManagementService.MANAGEMENT_FEATURE_ENUM.ResumeBuilder,
                    ]}
                    playFeatures={[ManagementService.PLAY_FEATURE_ENUM.Play_Status_Only]}
                    showSnackBar={() => null}
                    {...{
                      user: {
                        id: this.state.user.userDetail.userId,
                        ...this.state.user.userDetail,
                      },
                    }}
                    featureStatusAtFacility={this.state.featureStatusAtFacility}
                    currentLocationDisableText="person level"
                    parentLocationDisableText="hu or facility level"
                  />
                )}
              </DialogContent>
            </Dialog>
          </div>
        )}
        {this.state.user && (
          <BulkUserCard
            isOpen={this.state.isUserCardOpen}
            onClose={() => this.handleUserCard(false)}
            setResponse={(response) =>
              this.setState({
                response,
              })
            }
            users={[
              {
                personId: this.state.user.userDetail.userId,
              },
            ]}
          />
        )}
        {this.state.user && (
          <Dialog open={false} onClose={() => this.handleUserCard(false)}>
            <AppBar
              style={{
                position: 'relative',
              }}
            >
              <Toolbar>
                <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                  <Box display="flex" alignItems="center">
                    <IconButton edge="start" color="inherit" onClick={() => this.handleUserCard(false)} aria-label="close">
                      <CloseIcon />
                    </IconButton>
                    <Typography variant="h6">Learner Card</Typography>
                  </Box>
                </Box>
              </Toolbar>
            </AppBar>
            <div style={{ minWidth: 580 }}>
              <div style={{ width: '100%' }}>
                <img style={{ margin: '10px 0px 0px 25px' }} width="60px" src="/assets/logo/apds-report-logo.png" />
                <h3
                  style={{ textAlign: 'center' }}
                >{`${this.state.user.userLocations[0].locationName} User Login Information`}</h3>
              </div>
              <div>
                <h2 style={{ textAlign: 'center' }}>{this.state.user.userDetail.userName}</h2>
              </div>
              <div style={{ margin: '1rem' }}>
                <h5 style={{ marginBottom: '.2rem' }}>Connected Corrections</h5>
                <div style={{ padding: '.5rem' }}>
                  <p style={{ margin: '.2rem' }}>Username: {this.state.user.userDetail.userName}</p>
                  <p style={{ margin: '.2rem' }}>Password: {this.state.user.userDetail.password}</p>
                </div>
              </div>
              {this.state.user.userServices.map((s, i) => (
                <div key={i}>
                  {s.serviceKey != 124 && s.serviceKey != 125 ? (
                    <div style={{ margin: '1rem' }}>
                      <h5 style={{ marginBottom: '.2rem' }}>{s.serviceName}</h5>
                      <div style={{ padding: '.5rem' }}>
                        <p style={{ margin: '.2rem' }}>Username: {s.externalUserName}</p>
                        <p style={{ margin: '.2rem' }}>Password: {s.externalUserPassword}</p>
                        <small>
                          Start Date: {s.startDate ? moment.parseZone(s.startDate).format(USER_CARD_DATETIME_FORMAT) : 'n/a'}{' '}
                          -{' '}
                        </small>
                        <small>
                          End Date: {s.endDate ? moment.parseZone(s.endDate).format(USER_CARD_DATETIME_FORMAT) : 'n/a'}
                        </small>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              ))}
            </div>
          </Dialog>
        )}
      </Box>
    );
  }
}

export default withStyles(styles)(
  withRouter(withUnleash(Learner, UnleashService.FLAGS.COMMUNICATION_TAB_FOR_ADMIN_ONLY))
);