export default (theme) => ({
  root: {
    display: 'flex',
    padding: '0px 0px 16px 24px',
    flexDirection: 'column',
    gap: 24,
    [theme.breakpoints.down('sm')]: {
      padding: '0px 16px 16px 16px',
    },
  },
  title: {
    color: theme.palette.colors.ceruleanMain,
    textTransform: 'uppercase',
    letterSpacing: '1.8px',
  },
  divider: {
    width: '100%',
  },
  editAction: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row',
      alignItems: 'center',
      width: '100%',
    },
  },
  inputLabelBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 24,
    '& > div:first-child': {
      flex: '0 0 200px',
    },
    '& > div:last-child': {
      flex: '0 0 320px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div:first-child, & > div:last-child': {
        flex: '1 0 100%',
      },
    },
  },
  inputLabel: {
    width: '320px !important',
    '& .MuiInputBase-root': {
      width: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
  },
  inputUrlBox: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    gap: 24,
    '& > div:first-child': {
      flex: '0 0 200px',
      whiteSpace: 'nowrap',
      '& .MuiTypography-body1': {
        whiteSpace: 'nowrap',
      },
    },
    '& > div:last-child': {
      flex: '0 0 320px',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& > div:first-child, & > div:last-child': {
        flex: '1 0 100%',
      },
    },
  },
  inputUrl: {
    width: '320px !important',
    '& .MuiInputBase-root': {
      width: '320px',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
      '& .MuiInputBase-root': {
        width: '100%',
      },
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 12,
  },
  saveButton: {
    display: 'flex',
    height: '40px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'fit-content',
    borderRadius: theme.shape.borderRadius,
  },
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },
  switchArea: {
    display: 'flex',
    alignItems: 'center',
    gap: 32,
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      gap: 16,
    },
  },
  switchText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  subtitle: {
    color: theme.palette.colors.grey110,
    fontWeight: '700 !important',
  },
  description: {
    color: theme.palette.colors.grey110,
    fontWeight: 400,
    whiteSpace: 'normal', // Ensure text wrapping
  },
  switchRoot: {
    width: 70,
    height: 30,
    padding: 0,
  },
  switchBase: {
    color: theme.palette.colors.grey100,
    padding: '3px',
    paddingLeft: '5px',
  },
  switchThumb: {
    color: 'black',
    width: 30,
    height: 30,
  },
  switchTrackOn: {
    borderRadius: '20px !important',
    backgroundColor: `${theme.palette.primary.blue.main} !important`,
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:after': {
      content: "'Yes'",
      right: '10px',
    },
  },
  switchTrackOff: {
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: theme.palette.colors.grey110,
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:before': {
      content: "'No'",
      left: '8px',
    },
  },
  switchChecked: {
    color: 'white',
    left: '-62px !important',
    transform: 'translateX(100px) !important',
  },
  switchThumbIcon: {
    fontSize: '30px !important',
    color: 'white',
    width: '20px !important',
    padding: '2px !important',
    height: '20px !important',
  },
  errorTextField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'red',
      },
      '&:hover fieldset': {
        borderColor: 'red',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'red',
      },
    },
  },
});
