import React from 'react';
import PropTypes from 'prop-types';
import { Box, CircularProgress, Dialog, Icon, IconButton, withStyles } from '@material-ui/core';
import { PillButton, Typography } from '@orijinworks/frontend-commons';
import styles from './ads-list.style';
import { getSizeInMB } from '../../services/manage-ads.service';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';
import useAdsListHook from './useAdsListHook';

const AdsList = ({ classes, ads, hasAds, onAdDelete }) => {
  const { showConfirmationModal, showDeletingLoader, handleConfirmationModalClose, handleAdDelete, onConfirmDelete } =
    useAdsListHook({
      onAdDelete,
    });

  return (
    <Box className={classes.root}>
      <Dialog
        maxWidth="sm"
        open={showConfirmationModal}
        onClose={handleConfirmationModalClose}
        aria-labelledby="confirm-modal-title"
      >
        <Box className={classes.modalContent}>
          <Box className={classes.closeIconContainer}>
            <IconButton onClick={handleConfirmationModalClose}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </Box>
          <Box className={classes.modalText}>
            <Typography data-testid="dialog-title" id="confirm-modal-title" variant="h5" color="secondary">
              Remove active uploaded video?
            </Typography>
            <Typography data-testid="dialog-description" className={classes.description}>
              Are you sure you want to remove the current video? You will need to upload another video for an advertising
              video to display.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.modalActions}>
          {showDeletingLoader ? (
            <CircularProgress color="primary" />
          ) : (
            <PillButton
              data-testid="confirm-delete-btn"
              variant="contained"
              aria-label="Remove video"
              className={classes.confirmDeleteBtn}
              onClick={onConfirmDelete}
            >
              Remove video
            </PillButton>
          )}
          <PillButton
            data-testid="cancel-btn"
            color="secondary"
            variant="outlined"
            aria-label="Cancel"
            onClick={handleConfirmationModalClose}
          >
            Cancel
          </PillButton>
        </Box>
      </Dialog>

      <Typography data-testid="main-title" variant="overlineLarge" className={classes.title}>
        Active Uploaded Video
      </Typography>
      {hasAds() ? (
        ads.map((ad) => (
          <Box key={ad.id} className={classes.adItem}>
            <Box className={classes.adDetails}>
              <Icon data-testid="ad-icon" color="secondary" className={classes.videoIcon}>
                video_file
              </Icon>
              <Box className={classes.adText}>
                <Typography data-testid="ad-name" className={classes.adName}>
                  {ad.name || ''}
                </Typography>
                <Typography data-testid="ad-uploaded-date" className={classes.adDate}>
                  {ad.uploadedDate || ''}
                </Typography>
              </Box>
            </Box>
            <Box className={classes.adActions}>
              <Box className={classes.sizeChip}>
                <Typography data-testid="ad-size" className={classes.adSize}>
                  {getSizeInMB(ad.size || 0)} MB
                </Typography>
              </Box>
              <IconButton
                data-testid="ad-delete-button"
                className={classes.deleteButton}
                aria-label="Delete"
                onClick={() => {
                  handleAdDelete(ad);
                }}
              >
                <DeleteIcon className={classes.deleteIcon} />
              </IconButton>
            </Box>
          </Box>
        ))
      ) : (
        <Box className={classes.noContent}>
          <Typography data-testid="no-ads-message" className={classes.description}>
            No active video
          </Typography>
        </Box>
      )}
    </Box>
  );
};

AdsList.propTypes = {
  /**
   * The classes object is used to access the styles provided by withStyles
   */
  classes: PropTypes.object.isRequired,
  /**
   * The ads array is used to display the list of ads
   */
  ads: PropTypes.array.isRequired,
  /**
   * The hasAds function is used to check if there are any ads
   */
  hasAds: PropTypes.func.isRequired,
  /**
   * The onAdDelete function is used to delete an ad
   */
  onAdDelete: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdsList);
