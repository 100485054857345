import { withStyles } from '@material-ui/styles';
import React from 'react';
import styles from './call-details-modal-v2.style';
import { Box, Button, Typography, Grid, Snackbar, Drawer, IconButton } from '@material-ui/core';
import { CustomIcons, icons } from '../../../../v2/shared/components/custom-icons';
import { useCallDetailsModalHook } from '../call-details-modal/useCallDetailsModalHook';
import VoiceInsightChip from '../voice-insight-chip/voice-insight-chip';
import PropTypes from 'prop-types';
import { SNACK_BAR_SUCCESS, SNACK_BAR_TIMEOUT } from '../../../../v2/services/constants';
import MuiAlert from '@material-ui/lab/Alert';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import PhoneForwardedIcon from '@material-ui/icons/PhoneForwarded';
import HeadsetRoundedIcon from '@material-ui/icons/HeadsetRounded';
import FlaggedKeywords from '../flagged-keywords/flagged-keywords';
import { VOICE_CALL } from '../../constants/voice-call-constants';
/**
 * Represents a modal component for displaying call details.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @param {boolean} props.isDetailModalOpen - Indicates whether the detail modal is open or not.
 * @param {Function} props.setDetailModalState - A function to set the state of the detail modal.
 * @param {string} props.learnerFirstName - The first name of the learner.
 * @param {string} props.learnerLastName - The last name of the learner.
 * @param {string} props.learnerDocId - The document ID of the learner.
 * @param {string} props.contactPhoneNumber - The phone number of the contact.
 * @param {Object} props.contactInfo - The contact information.
 * @param {string} props.attendeeId - The ID of the attendee.
 * @param {string} props.meetingId - The ID of the meeting.
 * @param {string} props.callStatus - The status of the call.
 * @param {Function} props.handleMuteUnmuteMic - A function to handle mute/unmute microphone.
 * @param {boolean} props.muteMic - Indicates whether the microphone is muted or not.
 * @param {string} props.transactionId - The ID of the transaction.
 * @returns {JSX.Element} The JSX element representing the CallDetailsModal component.
 */
const CallDetailsDrawer = ({
  classes,
  isDetailModalOpen,
  setDetailModalState,
  learnerFirstName,
  learnerLastName,
  learnerDocId,
  contactPhoneNumber,
  contactInfo,
  attendeeId,
  meetingId,
  callStatus,
  handleMuteUnmuteMic,
  muteMic,
  transactionId,
  liveVoiceCallInsights,
}) => {
  const {
    onLeaveCall,
    blockUserAndTerminateCall,
    onTerminateCall,
    terminateCall,
    setTerminateCall,
    showToast,
    setShowToast,
    detectedKeywords,
    activeCallInsights,
  } = useCallDetailsModalHook(isDetailModalOpen, setDetailModalState, meetingId, attendeeId, liveVoiceCallInsights);
  return (
    <Drawer anchor="right" open={isDetailModalOpen} className={classes.root}>
      <Box className={classes.drawerHeader}>
        <IconButton
          id="leave-btn"
          aria-label="close"
          className={classes.closeIcon}
          onClick={() => {
            onLeaveCall(meetingId, attendeeId);
            setTerminateCall(false);
          }}
        >
          <KeyboardDoubleArrowRightIcon />
        </IconButton>
      </Box>
      <Box className={classes.drawer}>
        <Header
          classes={classes}
          callStatus={callStatus}
          onLeaveCall={onLeaveCall}
          meetingId={meetingId}
          attendeeId={attendeeId}
          setTerminateCall={setTerminateCall}
        />
        <Box className={classes.notificationPanel}>
          <IconButton id="notification-panel-icon">
            <HeadsetRoundedIcon className={classes.headphonesBatteryRounded} />
          </IconButton>
          <Typography id="notification-panel-text" variant="body1">
            THIS CALL IS LIVE
          </Typography>
        </Box>

        <Body
          classes={classes}
          learnerFirstName={learnerFirstName}
          learnerLastName={learnerLastName}
          learnerDocId={learnerDocId}
          contactPhoneNumber={contactPhoneNumber}
          contactInfo={contactInfo}
          activeCallInsights={activeCallInsights}
        />
        <Actions
          classes={classes}
          terminateCall={terminateCall}
          muteMic={muteMic}
          handleMuteUnmuteMic={handleMuteUnmuteMic}
          setTerminateCall={setTerminateCall}
          blockUserAndTerminateCall={blockUserAndTerminateCall}
          meetingId={meetingId}
          transactionId={transactionId}
        />
        <TerminateCall
          classes={classes}
          terminateCall={terminateCall}
          onTerminateCall={onTerminateCall}
          meetingId={meetingId}
          transactionId={transactionId}
          setTerminateCall={setTerminateCall}
        />
        {/* {detectedKeywords?.length > 0 &&
          <Box className={classes.voiceInsightChip}>
            <Typography variant="h6" data-testid="call-insight-title" className={classes.callInsightTextTitle}>
              Call Insights:
            </Typography>
            <Box className={classes.voiceInsightChip}>
              <VoiceInsightChip key="keyword-detected" label="Keyword Detected" data-testid="voice-insight-chip" />
              <Typography variant="body2" id="call_insight" data-testid="call-insight-text" className={classes.callInsightText}>
                One or more keywords from the facility dictionary were detected during this call.
              </Typography>
            </Box>
          </Box>
        } */}
        {activeCallInsights && Object.keys(activeCallInsights).length > 0 && activeCallInsights.insights?.length > 0 && (
          <Box className={classes.voiceInsightChip}>
            <Typography
              variant="body2"
              id="call_insight"
              data-testid="call-insight-title"
              className={classes.callInsightTextTitle}
            >
              <span>Call Insights</span>:{' '}
            </Typography>
            <Box className={classes.voiceInsightChip}>{renderCallInsights(activeCallInsights, classes)}</Box>
          </Box>
        )}

        {detectedKeywords?.length > 0 && (
          <Box id="keywords-section" className={classes.flaggedKeywordsSection}>
            <FlaggedKeywords keywordsData={detectedKeywords} />
          </Box>
        )}

        <Snackbar
          open={showToast}
          autoHideDuration={SNACK_BAR_TIMEOUT}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          onClose={() => setShowToast(false)}
        >
          <MuiAlert severity={SNACK_BAR_SUCCESS} elevation={6} variant="filled">
            Call Terminated Successfully
          </MuiAlert>
        </Snackbar>
      </Box>
    </Drawer>
  );
};

const renderCallInsights = (voiceCallInsights, classes) => {
  if (!voiceCallInsights || !voiceCallInsights.insights || !Array.isArray(voiceCallInsights.insights)) {
    return null;
  }

  const insights = voiceCallInsights.insights;

  const seenEventTypes = new Set();
  const uniqueInsights = [];

  insights.forEach((event) => {
    const eventKey = `${event.eventType}-${event.eventDisplayName}`;

    if (!seenEventTypes.has(eventKey)) {
      seenEventTypes.add(eventKey);
      uniqueInsights.push(event);
    }
  });

  return uniqueInsights.map((event, index) => {
    let description = '';
    let chipLabel = '';
    let bulletPoints = null;
    const renderedPersonKeys = new Set();

    switch (event.eventDisplayName) {
      case 'Keyword Detected':
        chipLabel = 'Keyword Detected';
        description = 'One or more keywords from the facility dictionary were detected during this call.';
        break;

      case 'Unknown Resident':
        chipLabel = 'Unknown Resident';
        description =
          "An unrecognized voice was detected from the resident's side of the call, indicating a possible unauthorized user of the tablet.";
        break;

      case 'Account Sharing':
        chipLabel = 'Account Sharing';
        description =
          "Other resident voices were detected on the resident's side of this call, indicating possible account sharing.";
        bulletPoints = (
          <ul>
            {insights
              .filter(
                (shareEvent) =>
                  shareEvent.eventType === 'ACCOUNT_SHARING' || shareEvent.eventDisplayName === 'Account Sharing'
              )
              .map((shareEvent) => {
                const confidenceScore = Math.round(shareEvent.confidenceScore * 100);

                // Check if personKey is already rendered or confidenceScore is NaN
                if (!renderedPersonKeys.has(`${shareEvent.eventType}-${shareEvent.personKey}`) && !isNaN(confidenceScore)) {
                  renderedPersonKeys.add(`${shareEvent.eventType}-${shareEvent.personKey}`);
                  return (
                    <li key={shareEvent.personKey}>
                      <strong>
                        {shareEvent.personName || shareEvent.personKey} | Confidence Level {confidenceScore}%
                      </strong>
                    </li>
                  );
                }
                return null;
              })}
          </ul>
        );

        break;

      case 'Three Way Calling':
        chipLabel = 'Three Way Calling';
        description =
          "Resident voices were detected on the contact's side of this call, suggesting potential three-way calling.";
        bulletPoints = (
          <ul>
            {insights
              .filter(
                (shareEvent) =>
                  shareEvent.eventType === 'THREE_WAY_CALLING' || shareEvent.eventDisplayName === 'Three Way Calling'
              )
              .map((shareEvent) => {
                const confidenceScore = Math.round(shareEvent.confidenceScore * 100);

                if (!renderedPersonKeys.has(`${shareEvent.eventType}-${shareEvent.personKey}`) && !isNaN(confidenceScore)) {
                  renderedPersonKeys.add(`${shareEvent.eventType}-${shareEvent.personKey}`);
                  return (
                    <li key={shareEvent.personKey}>
                      <strong>
                        {shareEvent.personName || shareEvent.personKey} | Confidence Level {confidenceScore}%
                      </strong>
                    </li>
                  );
                }
                return null;
              })}
          </ul>
        );

        break;

      default:
        break;
    }

    if (!chipLabel && !description) {
      return null;
    }

    return (
      <Box key={index} className={classes.voiceInsightChip}>
        <VoiceInsightChip label={chipLabel} data-testid="voice-insight-chip" />
        <Typography variant="body2" id="call_insight" data-testid="call-insight-text" className={classes.callInsightText}>
          {description}
        </Typography>
        {bulletPoints}
      </Box>
    );
  });
};

/**
 * Renders the header component for the call details modal.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The CSS classes for styling the header.
 * @param {string} props.callStatus - The status of the call.
 * @param {Function} props.onLeaveCall - The function to be called when leaving the call.
 * @param {string} props.meetingId - The ID of the meeting.
 * @param {string} props.attendeeId - The ID of the attendee.
 * @param {Function} props.setTerminateCall - The function to set the termination status of the call.
 * @returns {JSX.Element} The rendered header component.
 */
const Header = ({ classes, callStatus, onLeaveCall, meetingId, attendeeId, setTerminateCall }) => (
  <Box className={classes.header}>
    <Typography variant="h1" id="" className={classes.headerText}>
      Call Details
    </Typography>
  </Box>
);

/**
 * Renders the body of the call details modal.
 *
 * @param {Object} props - The component props.
 * @param {string} props.classes - The CSS classes for styling the component.
 * @param {string} props.learnerFirstName - The first name of the learner.
 * @param {string} props.learnerLastName - The last name of the learner.
 * @param {string} props.learnerDocId - The document ID of the learner.
 * @param {string} props.contactPhoneNumber - The phone number of the contact.
 * @param {string} props.contactInfo - The contact information in JSON format.
 * @returns {JSX.Element} The rendered body component.
 */
const Body = ({
  classes,
  learnerFirstName,
  learnerLastName,
  learnerDocId,
  contactPhoneNumber,
  contactInfo,
  activeCallInsights,
}) => {
  const contactInfoObj = contactInfo ? JSON.parse(contactInfo) : {};

  return (
    <Box className={classes.body}>
      <Box className={classes.callDetailsSections} id="call-details-section">
        <Box className={classes.groupDetatilBox}>
          <Typography id="learner-username" className={classes.displayNameLabel}>
            {`${learnerFirstName} ${learnerLastName}`}
          </Typography>
          <Typography className={classes.learnerText}>RESIDENT</Typography>
          <Typography id="learner-doc-id" className={classes.groupDetailSubText}>
            {learnerDocId}
          </Typography>
        </Box>
        <IconButton>
          <PhoneForwardedIcon className={classes.phoneForwardedIcon} />
        </IconButton>

        <Box className={classes.groupDetatilBox}>
          <Typography id="learner-full-name" className={classes.displayNameLabel}>
            {`${contactInfoObj.firstName} ${contactInfoObj.lastName}`}
          </Typography>
          <Typography className={classes.visitorText}>VISITOR</Typography>
          <Typography id="learner-contact-details" className={classes.groupDetailSubText}>
            {`${contactPhoneNumber} | ${contactInfoObj.relationshipType}`}
          </Typography>
        </Box>
      </Box>
      <>
        {activeCallInsights?.insights?.length > 0 &&
          (() => {
            const firstInsight = activeCallInsights.insights.find(
              (insight) =>
                (insight.eventType === VOICE_CALL.VOICE_INSIGHT_EVENT.VOICE_MATCH_RESIDENT ||
                  insight.eventDisplayName === 'Voice Match Resident') &&
                typeof insight.confidenceScore === 'number'
            );

            return firstInsight ? (
              <Grid xs={12}>
                <Typography variant="body2" id="confidence_score_0" className={classes.textGreen}>
                  Voice ID Confidence Rating: {(firstInsight.confidenceScore * 100).toFixed(2)}%
                </Typography>
              </Grid>
            ) : null; // Render nothing if no valid insight is found
          })()}
      </>
    </Box>
  );
};

/**
 * Renders the actions component for the call details modal.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @param {boolean} props.terminateCall - Flag indicating if the call should be terminated.
 * @param {boolean} props.muteMic - Flag indicating if the microphone is muted.
 * @param {Function} props.handleMuteUnmuteMic - Function to handle muting/unmuting the microphone.
 * @param {Function} props.setTerminateCall - Function to set the terminate call flag.
 * @returns {JSX.Element|null} The rendered actions component or null if terminateCall is true.
 */
const Actions = ({
  classes,
  meetingId,
  transactionId,
  terminateCall,
  muteMic,
  handleMuteUnmuteMic,
  setTerminateCall,
  blockUserAndTerminateCall,
}) => {
  if (terminateCall) return null;

  return (
    <>
      <Typography className={classes.inCallActionsText}>In-Call Actions</Typography>
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        spacing={2}
        className={classes.callActionsBtnGrid}
      >
        <Grid item>
          <Button
            id="speak-btn"
            variant="outlined"
            startIcon={<CustomIcons icon={muteMic ? icons.MicOffIcon : icons.MicIcon} />}
            className={classes.speakButton}
            onClick={() => handleMuteUnmuteMic(!muteMic)}
          >
            Speak
          </Button>
        </Grid>
        <Grid item>
          <Button
            id="block-btn"
            variant="outlined"
            startIcon={<CustomIcons icon={icons.BlockIcon} />}
            className={classes.blockButton}
            onClick={() => blockUserAndTerminateCall(meetingId, transactionId)}
          >
            Block
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            id="terminate-call-btn"
            startIcon={<CustomIcons icon={icons.CancelIcon} />}
            className={classes.terminateButton}
            onClick={() => setTerminateCall(true)}
          >
            Terminate Call
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

/**
 * Renders the TerminateCall component.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.classes - The CSS classes for styling the component.
 * @param {boolean} props.terminateCall - Flag indicating whether to render the TerminateCall component or not.
 * @param {Function} props.onTerminateCall - Callback function to be called when the Terminate button is clicked.
 * @param {string} props.meetingId - The ID of the meeting.
 * @param {string} props.transactionId - The ID of the transaction.
 * @param {Function} props.setTerminateCall - Callback function to set the terminateCall flag.
 * @returns {JSX.Element|null} The rendered TerminateCall component or null if terminateCall is false.
 */
const TerminateCall = ({ classes, terminateCall, onTerminateCall, meetingId, transactionId, setTerminateCall }) => {
  if (!terminateCall) return null;

  return (
    <Box className={classes.terminateCallSection} id="terminate-call-section">
      <Typography className={classes.terminateCallTitle} id="termination-call-title">
        <CustomIcons icon={icons.CancelIcon} className={classes.cancelIcon} />
        Terminate Call
      </Typography>

      <Typography className={classes.terminateCallDescription} id="terminate-call-description">
        Terminating will end the call for Learner and the Visitor. Would you like to continue?
      </Typography>

      <Box className={classes.terminateCallButtonsSection} id="terminate-call-buttons-section">
        <Button
          id="terminate-btn"
          onClick={() => onTerminateCall(meetingId, transactionId)}
          className={classes.terminateCallButton}
          startIcon={<CustomIcons icon={icons.CancelIcon} />}
        >
          Terminate
        </Button>
        <Button variant="outlined" id="cancel-btn" className={classes.blockButton} onClick={() => setTerminateCall(false)}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

CallDetailsDrawer.propTypes = {
  isDetailModalOpen: PropTypes.bool.isRequired,
  setDetailModalState: PropTypes.func.isRequired,
  learnerUserName: PropTypes.string,
  learnerDocId: PropTypes.string,
  contactPhoneNumber: PropTypes.string,
  contactInfo: PropTypes.object,
  attendeeId: PropTypes.string,
  meetingId: PropTypes.string,
  handleMuteUnmuteMic: PropTypes.func,
  muteMic: PropTypes.bool,
};
export default withStyles(styles)(CallDetailsDrawer);
