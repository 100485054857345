import {
  getContactsList,
  getLiveCallsList,
  updateContact,
  adminJoinCall,
  adminLeaveCall,
  getVoiceCallDetails,
  adminTerminateCall,
  adminBlockUserAndTerminatesCall,
  getLearnersList,
  getContactsByLearnerId,
  getScheduleList,
  createSchedule,
  updateVoiceCallSchedule,
  assignSchedule,
  unassignSchedule,
  getKeywordsListByLocation,
  addKeywordsToDictionary,
  getKeywordDetails,
  getBaseVoiceKeywordsJson,
  createKeywordDictionary,
  getVoiceProfile
} from '../../../util/APIUtils';

export class VoiceCallService {
  /**
   * Gets contact list against location Id
   * @param {string} locationId - The ID of the location. (Required)
   * @returns {Promise<Object>} A promise that resolves with the list of contacts data.
   */
  getContactsList = ({ locationId, pageNo, pageSize, sortBy, sortOrder, callStatus, tbody }) => {
    return new Promise((resolve, reject) => {
      getContactsList({ locationId, pageNo, pageSize, sortBy, sortOrder, callStatus, tbody }).then((data) => {
        resolve(data);
      });
    });
  };

  getLearnersList = ({ locationId, pageNo, pageSize, tbody, sortBy, sortOrder }) => {
    return new Promise((resolve, reject) => {
      getLearnersList({ locationId, pageNo, pageSize, tbody, sortBy, sortOrder }).then((data) => {
        resolve(data);
      });
    });
  };

  getScheduledList = ({ locationId, scheduleId, pageNo, pageSize, sortBy, sortOrder }) => {
    return new Promise((resolve, reject) => {
      getScheduleList({ locationId, scheduleId, pageNo, pageSize, sortBy, sortOrder }).then((data) => {
        resolve(data);
      });
    });
  };

  createSchedule = ({ locationId, scheduleName }) => {
    return new Promise((resolve, reject) => {
      createSchedule({ locationId, scheduleName })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  updateSchedule = (scheduleObj) => {
    return new Promise((resolve, reject) => {
      updateVoiceCallSchedule(scheduleObj)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  /**
   * Updates a contact's information.
   *
   * @param {Object} contact - The contact object to be updated.
   * @param {string} contact.id - The ID of the contact. (Required)
   * @param {string} contact.firstName - The first name of the contact. (Required)
   * @param {string} contact.lastName - The last name of the contact. (Required)
   * @param {string} contact.phoneNumber - The phone number of the contact. (Required)
   * @param {string} contact.status - The status of the contact. (Required)
   * @returns {Promise<Object>} A promise that resolves with the updated contact data.
   */
  updateContact = (contact) => {
    return new Promise((resolve, reject) => {
      updateContact(contact)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getLiveCallsList = (liveCallPayload) => {
    return new Promise((resolve, reject) => {
      getLiveCallsList(liveCallPayload).then((data) => {
        resolve(data);
      });
    });
  };

  adminJoinCall = (meetingId) => {
    return new Promise((resolve, reject) => {
      adminJoinCall(meetingId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  adminLeaveCall = (meetingId, attendeeId) => {
    return new Promise((resolve, reject) => {
      adminLeaveCall(meetingId, attendeeId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  adminTerminateCall = (meetingId, transactionId) => {
    return new Promise((resolve, reject) => {
      adminTerminateCall(meetingId, transactionId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  adminBlockUserAndTerminatesCall = (meetingId, transactionId) => {
    return new Promise((resolve, reject) => {
      adminBlockUserAndTerminatesCall(meetingId, transactionId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  getVoiceCallDetails = (meetingId) => {
    return new Promise((resolve, reject) => {
      getVoiceCallDetails(meetingId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getVoiceCallTranscription = async (preSignedURLTranscript) => {
    try {
      const response = await fetch(preSignedURLTranscript);
      if (!response.ok) {
        console.error('Network response was not ok');
      }
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  };

  getContactsByLearnerId = ({ learnerId, pageSize, pageNumber, orderBy, orderDirection, requestPayload }) => {
    return new Promise((resolve, reject) => {
      getContactsByLearnerId({ learnerId, pageSize, pageNumber, orderBy, orderDirection, requestPayload })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  assignSchedule = (scheduleAssignmentData) => {
    return new Promise((resolve, reject) => {
      assignSchedule(scheduleAssignmentData)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  unassignSchedule = (scheduleAssignmentData) => {
    return new Promise((resolve, reject) => {
      unassignSchedule(scheduleAssignmentData)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getKeywordsListByLocation = ({ locationId, sortBy, sortOrder, searchKeyword, page, pageSize }) => {
    return new Promise((resolve, reject) => {
      getKeywordsListByLocation({ locationId, sortBy, sortOrder, searchKeyword, page, pageSize })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  addKeywordsToDictionary = (keywordsPayload, dictionaryId) => {
    return new Promise((resolve, reject) => {
      addKeywordsToDictionary(keywordsPayload, dictionaryId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getKeywordDetails = ({ dictionaryId, keyword, pageNumber, pageSize, sortBy, sortOrder }, filters) => {
    return new Promise((resolve, reject) => {
      getKeywordDetails({ dictionaryId, keyword, pageNumber, pageSize, sortBy, sortOrder }, filters)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  getBaseVoiceKeywordsJson = () => {
    return new Promise((resolve, reject) => {
      getBaseVoiceKeywordsJson()
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  createKeywordDictionary = (keywordDictionaryObj) => {
    return new Promise((resolve, reject) => {
      createKeywordDictionary(keywordDictionaryObj)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  getVoiceProfile = (voiceProfileId) => {
    return new Promise((resolve, reject) => {
      getVoiceProfile(voiceProfileId)
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
}
