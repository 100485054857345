import { USER_CONTEXT } from "../../../../constants";

const useTabDetailsHook = ({profileId, allTabs, global}) => {
    const TABS = [
        {
            id: allTabs.Details,
            label: "Details"
        },
        {
            id: allTabs.Relationship,
            label: "Relationship"
        },
        {
            id: allTabs.Roles,
            label: "Roles"

        }
    ];

    if (profileId === USER_CONTEXT.personId) {
        TABS.push({
            id: allTabs.Learner_Group,
            label: "Learner Groups"
        })
    }

    if(global.isSuperAdmin || global.isFacilityAdmin) {
        TABS.splice(1, 0, {
            id: allTabs.Communication,
            label: "Communication"
        });
    }

    return TABS;
}

export default useTabDetailsHook;