export default (theme) => ({
  root: {
    display: 'flex',
    padding: '0px 0px 24px 24px',
    flexDirection: 'column',
    gap: 10,
  },
  divider: {
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    paddingTop: 14,
  },
  uploadContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: 56,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    width: '100%',
    borderRadius: 10,
    border: `1px dashed ${theme.palette.colors.grey100}`,
  },
  greyedIcon: {
    fontSize: 48,
    color: theme.palette.colors.grey80,
  },
  title: {
    color: 'black',
    fontWeight: '700 !important',
  },
  description: {
    maxWidth: 360,
    textAlign: 'center',
    color: theme.palette.colors.grey100,
  },
  hidden: {
    display: 'none',
  },
  uploadingContainer: {
    display: 'flex',
    padding: '11px 16px 11px 0px',
    alignItems: 'center',
    gap: 16,
    borderRadius: 5,
    backgroundColor: 'white',
    width: '100%',
  },
  bluedIcon: {
    fontSize: 24,
    color: theme.palette.colors.ceruleanMain,
  },
  uploadProgress: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    flex: '1 0 0',
  },
  fileData: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  fileName: {
    color: 'rgba(0, 0, 0, 0.40)',
    fontWeight: '700 !important',
  },
  fileSize: {
    color: theme.palette.colors.grey100,
  },
  progressRoot: {
    backgroundColor: theme.palette.colors.grey60,
  },
  progressBar: {
    backgroundColor: theme.palette.colors.ceruleanMain,
  },
});
