import React from "react";
import { Tab, Tabs } from "@material-ui/core";
export const LearnerTabs = ({
    classes,
    unleashProps,
    activeTab,
    changeTabHandler,
    global,
    handleTranscriptDialog,
    handleViewFormsDialog,
    isCompletionReportFlagEnabled
}) => {
    const TAB_ITEMS = [
        { label: 'Insights', onClick: null, value: 0, show: true },
        { label: 'Location history', onClick: null, value: 1, show: (global.isSuperAdmin || global.isFacilityAdmin) },
        { label: 'Programs', onClick: null, value: 2, show: true },
        { label: 'Explore', onClick: null, value: 3, show: global.isSuperAdmin },
        { label: 'Communication', onClick: null, value: 4, show: global.isSuperAdmin || global.isFacilityAdmin || unleashProps.isFlagEnabled() },
        { label: 'Relationships', onClick: null, value: 5, show: true },
        { label: isCompletionReportFlagEnabled ? 'Completion Report' : 'Progress Report', onClick: null, value: 6, show: true },
        { label: 'Forms', onClick: handleViewFormsDialog, value: 7, show: global.isSuperAdmin || global.isFacilityAdmin },
        { label: 'Time on Task', onClick: null, value: 8, show: true },
    ]

    return (
        <Tabs
            value={activeTab}
            classes={{
                indicator: classes.tabIndicator,
                root: classes.tabContainer
            }}
            indicatorColor="primary"
            textColor="primary"
            onChange={(event, newValue) => {
                changeTabHandler(newValue);
            }}
            aria-label="User profile tabs"
            variant={'scrollable'}
            scrollButtons={'on'}
        >
            {TAB_ITEMS.map(tab =>
                tab.show &&
                <Tab
                    key={tab.value}
                    classes={{
                        root: classes.tabButton,
                        selected: classes.selectedTabButton
                    }}
                    onClick={tab.onClick}
                    label={tab.label}
                    value={tab.value}
                />
            )}
        </Tabs>
    )
}