import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Icon, LinearProgress, withStyles } from '@material-ui/core';
import styles from './ads-upload.style';
import { PillButton, Typography } from '@orijinworks/frontend-commons';
import useAdsUploadHook from './useAdsUploadHook';
import { getSizeInMB } from '../../services/manage-ads.service';

/**
 * Component to upload ads
 *
 * @param {object} props - The props object
 * @returns {JSX.Element} - The JSX element
 */
const AdsUpload = ({ classes, ...props }) => {
  const fileInputRef = useRef(null);
  const { isUploading, selectedFile, getAllowedFileTypes, handleVideoUpload } = useAdsUploadHook(props);

  return (
    <Box className={classes.root}>
      <input
        data-testid="file-input"
        ref={fileInputRef}
        type="file"
        accept={getAllowedFileTypes()}
        onChange={handleVideoUpload}
        onClick={(event) => {
          event.target.value = null;
        }}
        className={classes.hidden}
      />

      <Typography data-testid="ads-upload-title" variant="h2" color="secondary">
        Video Upload
      </Typography>
      <Divider className={classes.divider} />
      <Box className={classes.wrapper}>
        {isUploading ? (
          <Box className={classes.uploadingContainer}>
            <Icon className={classes.bluedIcon}>upload_file</Icon>
            <Box className={classes.uploadProgress}>
              <Box className={classes.fileData}>
                <Typography data-testid="uploaded-file-name" className={classes.fileName}>
                  {selectedFile?.name || ''}
                </Typography>
                <Typography data-testid="uploaded-file-size" className={classes.fileSize}>
                  {getSizeInMB(selectedFile?.size || 0)}MB
                </Typography>
              </Box>
              <LinearProgress classes={{ root: classes.progressRoot, bar: classes.progressBar }} />
            </Box>
          </Box>
        ) : (
          <Box className={classes.uploadContainer}>
            <Icon className={classes.greyedIcon}>upload_file</Icon>
            <Typography data-testid="upload-file-title" className={classes.title}>
              Select a video to upload
            </Typography>
            <Typography data-testid="upload-file-description" className={classes.description}>
              The video should be in MP4 format and should not exceed 10MB in size. This will replace any active video.
            </Typography>
            <PillButton
              data-testid="select-video-button"
              color="primary"
              variant="outlined"
              aria-label="Select Video"
              onClick={() => {
                fileInputRef.current.click();
              }}
            >
              Select Video
            </PillButton>
          </Box>
        )}
      </Box>
    </Box>
  );
};

AdsUpload.propTypes = {
  /**
   * The classes object is used to access the styles provided by withStyles
   */
  classes: PropTypes.object.isRequired,
  /**
   * The onUpload function is used to handle the video upload
   */
  onUpload: PropTypes.func.isRequired,
  /**
   * The toggleError function is used to toggle the error message
   */
  toggleError: PropTypes.func.isRequired,
};

export default withStyles(styles)(AdsUpload);
