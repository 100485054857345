import { useState, useEffect } from 'react';
import { VoiceCallService } from '../../services/voice-call.service';

const useVoiceIDListeningModalHook = ({ voiceProfileId }) => {
  const voiceCallService = new VoiceCallService();
  const [voiceProfileRecordingUrl, setVoiceProfileRecordingUrl] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    getVoiceProfileRecordingUrl(voiceProfileId);
  }, []);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handlePlay = () => {
    setIsPlaying(true);
  };

  const handlePause = () => {
    setIsPlaying(false);
  };

  const getVoiceProfileRecordingUrl = async (voiceProfileId) => {
    try {
      const response = await voiceCallService.getVoiceProfile(voiceProfileId);
      if (response) {
        setVoiceProfileRecordingUrl(response.presignedUrlVoiceProfileRecording);
      }
    } catch (error) {
      console.error('Error fetching voice profile:', error);
    }
  };
  const handleDownload = (videoUrl, learnerName) => {
    if (!videoUrl) {
      console.error('Video URL is not available.');
      return;
    }
    const downloadDate = new Date().toISOString().split('T')[0];
    const sanitizedLearnerName = learnerName ? learnerName.replace(/\s+/g, '_') : 'Unknown_User';
    const fileName = `Voice_ID_Recording_${sanitizedLearnerName}_${downloadDate}.webm`; // Ensure the correct file extension
    const link = document.createElement('a');
    link.href = videoUrl;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return {
    voiceProfileRecordingUrl,
    handleDownload,
    handleMouseEnter,
    handleMouseLeave,
    handlePlay,
    handlePause,
    isHovered,
    isPlaying,
  };
};

export default useVoiceIDListeningModalHook;
