import { useState } from 'react';

/**
 * Custom hook to manage ads list
 *
 * @param {object} props - The props object containing the following properties:
 * - onAdDelete: function - Function to handle ad delete
 *
 * @returns {object} - Object containing the following properties:
 * - showConfirmationModal: boolean - Whether the confirmation modal should be shown
 * - showDeletingLoader: boolean - Whether the deleting loader should be shown
 * - handleConfirmationModalClose: function - Function to close the confirmation modal
 * - handleAdDelete: function - Function to handle ad delete
 * - onConfirmDelete: function - Function to confirm ad delete
 */
const useAdsListHook = ({ onAdDelete }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [adToDelete, setAdToDelete] = useState(null);
  const [showDeletingLoader, setShowDeletingLoader] = useState(false);

  /**
   * Function to close the confirmation modal
   */
  const handleConfirmationModalClose = () => {
    setAdToDelete(null);
    setShowConfirmationModal(false);
  };

  /**
   * Function to handle ad delete
   *
   * @param {object} ad - Ad object
   */
  const handleAdDelete = (ad) => {
    setAdToDelete(ad);
    setShowConfirmationModal(true);
  };

  /**
   * Function to confirm ad delete
   */
  const onConfirmDelete = () => {
    setShowDeletingLoader(true);
    onAdDelete(adToDelete, (err) => {
      if (!err) {
        handleConfirmationModalClose();
      }
      setShowDeletingLoader(false);
    });
  };

  return {
    showConfirmationModal,
    showDeletingLoader,
    handleConfirmationModalClose,
    handleAdDelete,
    onConfirmDelete,
  };
};

export default useAdsListHook;
