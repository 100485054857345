export default (theme) => ({
  root: {
    display: 'flex',
    paddingTop: 24,
    flexDirection: 'column',
    gap: 12,
  },
  header: {
    display: 'flex',
    padding: '0px 0px 12px 24px',
    flexDirection: 'column',
    gap: 24,
  },
  divider: {
    width: '100%',
    maxWidth: 960,
  },
  content: {
    display: 'flex',
    width: '100%',
    maxWidth: 960,
    flexDirection: 'column',
    gap: 10,
  },
  switchArea: {
    display: 'flex',
    padding: '0px 0px 32px 24px',
    alignItems: 'center',
    gap: 32,
  },
  switchText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  subtitle: {
    color: theme.palette.colors.grey110,
    fontWeight: '700 !important',
  },
  description: {
    color: theme.palette.colors.grey110,
    fontWeight: 400,
  },
  switchRoot: {
    width: 70,
    height: 30,
    padding: 0,
  },
  switchBase: {
    color: theme.palette.colors.grey100,
    padding: '3px',
    paddingLeft: '5px',
  },
  switchThumb: {
    color: 'black',
    width: 30,
    height: 30,
  },
  switchTrackOn: {
    borderRadius: '20px !important',
    backgroundColor: `${theme.palette.primary.blue.main} !important`,
    opacity: '1 !important',
    '&:after, &:before': {
      color: 'white',
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:after': {
      content: "'Yes'",
      right: '10px',
    },
  },
  switchTrackOff: {
    borderRadius: 20,
    backgroundColor: 'rgba(0, 0, 0, 0.26) !important',
    opacity: '1 !important',
    '&:after, &:before': {
      color: theme.palette.colors.grey110,
      fontSize: '15px',
      position: 'relative',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      top: '6px',
    },
    '&:before': {
      content: "'No'",
      left: '8px',
    },
  },
  switchChecked: {
    color: 'white',
    left: '-62px !important',
    transform: 'translateX(100px) !important',
  },
  switchThumbIcon: {
    fontSize: '30px !important',
    color: 'white',
    width: '20px !important',
    padding: '2px !important',
    height: '20px !important',
  },
  alert: {
    padding: '6px 16px !important',
  },
});
