export default (theme) => ({
  capitalize: {
    textTransform: 'capitalize',
  },
  success: {
    color: theme.status.success,
  },
  danger: {
    color: theme.status.danger,
  },
  warning: {
    color: theme.status.warning,
  },
  pending: {
    color: 'orange',
  },
  scheduleColumnStyle: {
    border: '1px solid #000',
    padding: '5px 10px',
    maxWidth: 'auto',
    fontWeight: 'bold',
    display: 'inline-block',
    borderRadius: '8px',
    color: theme.palette.primary.black,
  },
  scheduleAutoComplete: {
    marginTop: theme.spacing(2),
  },

  scheduleAutoCompleteTypography: {
    padding: '10px',
  },

  voiceIDPlayIcon: {
    color: '#0F48B8',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '8px',
    alignItems: 'center',
    borderRadius: ' 50%',
    boxShadow: '0 0 0 1px #0F48B8',
  },
});
