import React from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles, Divider, Switch, CircularProgress } from '@material-ui/core';
import { Button, Typography, TextField } from '@orijinworks/frontend-commons';
import styles from './call-to-action.style';
import EditIcon from '@material-ui/icons/Edit';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';
import useCallToActionHook from './useCallToActionHook';
import { DottedCircularProgress } from '../../../../shared/components/dotted-circular-progress/dotted-circular-progress';

const CallToAction = ({ classes, ads, isAdEnabled, toggleError }) => {
  const {
    isEnabled,
    isEditing,
    buttonLabel,
    buttonUrl,
    buttonLabelError,
    buttonUrlError,
    showCTALoader,
    showSaveLoader,
    uploadSuccess,
    showCancel,
    handleButtonLabelChange,
    handleButtonUrlChange,
    handleEditClick,
    handleUploadCTAData,
    handleCancelClick,
    handleCTAVisibility,
  } = useCallToActionHook(ads, isAdEnabled, toggleError);

  return (
    <Box className={classes.root}>
      <Typography data-testid="ads-upload-title" variant="h2" color="secondary">
        Call to Action
      </Typography>
      <Divider className={classes.divider} />
      {/* BEGIN: SWITCH AREA */}
      <Box className={classes.switchArea}>
        <Box className={classes.switchText}>
          <Typography data-testid="cta-switch-area-title" className={classes.subtitle}>
            Show call to action on video
          </Typography>
          <Typography data-testid="cta-switch-area-description" className={classes.description}>
            Enabling this feature will display the call to action hyperlink to the learner with the ad.
          </Typography>
        </Box>

        {showCTALoader ? (
          <CircularProgress color="primary" />
        ) : (
          <Switch
            classes={{
              root: classes.switchRoot,
              switchBase: classes.switchBase,
              thumb: classes.switchThumb,
              track: isEnabled ? classes.switchTrackOn : classes.switchTrackOff,
              checked: classes.switchChecked,
            }}
            inputProps={{ 'aria-label': 'Enable/Disable Call to Action' }}
            onChange={handleCTAVisibility}
            checked={isEnabled}
            name="enableCTA"
            icon={<CancelRoundedIcon className={classes.swicthThumbIcon} />}
            checkedIcon={<CheckCircleIcon className={classes.switchThumbIcon} />}
          />
        )}
      </Box>
      {/* END: SWITCH AREA */}
      <Box className={classes.editAction}>
        <Typography data-testid="main-title" variant="overlineLarge" className={classes.title}>
          Call to Action Details
        </Typography>
        {!isEditing && (
          <Button
            id="edit-button"
            variant="outlined"
            color="primary"
            startIcon={<EditIcon />}
            onClick={handleEditClick}
            disabled={!isEnabled}
          >
            Edit Call to Action
          </Button>
        )}
      </Box>
      <Box className={classes.inputLabelBox} sx={{ mb: 2 }}>
        <Box>
          <Typography id="call-action-label" variant="overlineSmall">
            Call to Action Button Label
          </Typography>
        </Box>
        <TextField
          value={buttonLabel}
          onChange={(e) => handleButtonLabelChange(e)}
          margin="normal"
          className={`${classes.inputLabel} ${buttonLabelError ? classes.errorTextField : ''}`}
          disabled={!isEnabled || !isEditing}
          error={!!buttonLabelError}
          helperText={buttonLabelError}
        />
      </Box>
      <Box className={classes.inputUrlBox} sx={{ mb: 2 }}>
        <Box>
          <Typography id="call-action-URL" variant="overlineSmall">
            Call to Action URL
          </Typography>
          <Typography variant="body1">If this field is left empty, call to action will not display.</Typography>
        </Box>
        <TextField
          value={buttonUrl}
          onChange={(e) => handleButtonUrlChange(e)}
          margin="normal"
          className={`${classes.inputUrl} ${buttonUrlError ? classes.errorTextField : ''}`}
          disabled={!isEnabled || !isEditing}
          error={!!buttonUrlError}
          helperText={buttonUrlError}
        />
      </Box>
      {(isEditing || uploadSuccess) && (
        <Box className={classes.actionButtons}>
          <Button
            data-testid="save-button"
            variant="contained"
            color="accent-teal"
            onClick={handleUploadCTAData}
            disabled={!!buttonLabelError || !!buttonUrlError}
          >
            {showSaveLoader ? (
              <DottedCircularProgress size={24} thickness={5} color="primary" />
            ) : uploadSuccess && !isEditing ? (
              <CheckIcon />
            ) : (
              'Save Changes'
            )}
          </Button>
          {showCancel && (
            <Button data-testid="cancel-button" variant="outlined" color="accent-teal" onClick={handleCancelClick}>
              Cancel
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

CallToAction.propTypes = {
  /**
   * The classes object is used to access the styles provided by withStyles
   */
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(CallToAction);
