import React from 'react';
import { Modal, Button } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/styles';
import { makeStyles } from '@material-ui/core/styles';
import styles from './voice-id-listening-modal-style';
import useVoiceIDListeningModalHook from './useVoiceIDListeningModalHook';
import { Box, IconButton } from '@material-ui/core';
import DownloadIcon from '@mui/icons-material/GetApp';

const useStyles = makeStyles((theme) => styles(theme));

const VoiceIdListeningModalComponent = ({
  voiceIDOpen,
  handleClose,
  learnerName,
  handleReRecordVoiceID,
  learnerId,
  voiceProfileId,
}) => {
  const {
    voiceProfileRecordingUrl,
    handleDownload,
    handleMouseEnter,
    handleMouseLeave,
    handlePlay,
    handlePause,
    isPlaying,
    isHovered,
  } = useVoiceIDListeningModalHook({ voiceProfileId });
  const classes = useStyles();
  return (
    <Box>
      <Modal
        open={voiceIDOpen}
        onClose={handleClose}
        showCrossIcon={true}
        heading={`${learnerName} Voice ID`}
        showFooter={false}
        styles={{
          mainWrapperStyles: {
            height: 'auto',
            width: '65vh',
          },
        }}
      >
        <Box
          className={classes.videoPlayer}
          position="relative"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <video
            controls
            src={voiceProfileRecordingUrl}
            width="100%"
            height="auto"
            controlsList="nodownload"
            onPlay={handlePlay}
            onPause={handlePause}
          />
          {(isHovered || !isPlaying) && (
            <IconButton
              onClick={() => handleDownload(voiceProfileRecordingUrl, learnerName)}
              style={{
                position: 'absolute',
                bottom: '35px',
                right: '150px',
                color: 'white',
              }}
            >
              <DownloadIcon />
            </IconButton>
          )}
        </Box>
        <Box className={classes.reRecordBox}>
          <Button
            id="modal-re-record-button"
            variant="outlined"
            className={classes.reRecordButton}
            onClick={() => {
              handleReRecordVoiceID({ learnerId, learnerName, voiceProfileId });
            }}
          >
            Re-record Voice ID
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default withStyles(styles)(VoiceIdListeningModalComponent);
