import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled } from '@mui/material/styles';

export const DottedCircularProgress = styled(CircularProgress)(({ theme }) => ({
    svg: {
        position:"relative",
        transformOrigin: 'center',
      circle: {
        color: 'white !important',
        animation: 'rotate 1.4s linear infinite',
        strokeLinecap: 'round',  
        strokeDasharray: '1, 8',
      },
    },
  }));