import { useCallback, useState } from 'react';
import { MANAGE_ADS_CONSTANTS } from '../../services/manage-ads.service';

/**
 * Custom hook to manage ads upload
 * 
 * @param {object} props - The props object containing the following properties:
 * - onUpload: function - Function to handle video upload
 * - toggleError: function - Function to toggle error message
 *  
 * @returns {object} - Object containing the following properties:
 * - isUploading: boolean - Whether the video is uploading
 * - selectedFile: object - The selected file object
 * - getAllowedFileTypes: function - Function to get allowed file types
 * - handleVideoUpload: function - Function to handle video upload
 */
const useAdsUploadHook = ({ onUpload, toggleError }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  /**
   * Function to get allowed file types
   */
  const getAllowedFileTypes = useCallback(() => {
    return MANAGE_ADS_CONSTANTS.ALLOWED_VIDEO_FORMATS.join(',');
  }, []);

  /**
   * Function to handle video upload
   * 
   * @param {object} e - Event object 
   * @returns {void}
   */
  const handleVideoUpload = (e) => {
    const file = e.target.files[0];

    if (!file) {
      return;
    }

    if (!MANAGE_ADS_CONSTANTS.ALLOWED_VIDEO_FORMATS.includes(file.type)) {
      toggleError('Invalid file type. Please upload MP4 files only.');
      return;
    }

    if (file.size > MANAGE_ADS_CONSTANTS.MAX_VIDEO_SIZE) {
      toggleError('File size exceeds the limit. Please upload a file less than 10MB.');
      return;
    }

    setIsUploading(true);
    setSelectedFile(file);
    onUpload(file, () => {
      setIsUploading(false);
      setSelectedFile(null);
    });
  };

  return {
    isUploading,
    selectedFile,
    getAllowedFileTypes,
    handleVideoUpload,
  };
};

export default useAdsUploadHook;
