export default (theme) => ({
  root: {
    display: 'flex',
    padding: '0px 0px 16px 24px',
    flexDirection: 'column',
    gap: 24,
  },
  title: {
    color: theme.palette.colors.ceruleanMain,
    textTransform: 'uppercase',
    letterSpacing: '1.8px',
  },
  noContent: {
    display: 'flex',
    padding: 24,
    borderRadius: 16,
    backgroundColor: theme.palette.colors.blue.pale,
  },
  description: {
    color: theme.palette.colors.grey110,
    fontWeight: 400,
  },
  adItem: {
    display: 'flex',
    padding: '12px 0px',
    alignItems: 'center',
    gap: 14,
  },
  adDetails: {
    display: 'flex',
    flex: '1 0 0',
    gap: 16,
  },
  videoIcon: {
    fontSize: 16,
  },
  adText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    flex: '1 0 0',
  },
  adName: {
    color: theme.palette.colors.grey110,
    fontWeight: '700 !important',
  },
  adDate: {
    color: theme.palette.colors.grey110,
  },
  adActions: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  sizeChip: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '6px 12px',
    borderRadius: 4,
    border: `1px solid ${theme.palette.colors.grey100}`,
    width: 86,
  },
  adSize: {
    color: theme.palette.colors.grey100,
    fontWeight: '700 !important',
    textAlign: 'center',
  },
  deleteButton: {
    border: `1px solid ${theme.palette.colors.redMain}`,
  },
  deleteIcon: {
    color: theme.palette.colors.redMain,
    fontSize: 24,
    marginRight: '0px !important',
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 24,
    gap: 24,
  },
  closeIconContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  closeIcon: {
    color: theme.palette.primary.deep,
    fontSize: 24,
  },
  modalText: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  modalActions: {
    display: 'flex',
    height: 80,
    padding: '0px 24px',
    alignItems: 'center',
    gap: 12,
  },
  confirmDeleteBtn: {
    backgroundColor: `${theme.palette.colors.redMain} !important`,
  },
});
