import React from 'reactn';
import {
  Card,
  CardContent,
  withStyles,
  Box,
  Typography,
  Tooltip,
  ListItemSecondaryAction,
  Grid,
  IconButton,
  TextField,
  List,
  ListItem,
  ListItemText,
  Button,
} from '@material-ui/core';

import { Accordion, Loader } from '../../..';
import styles from '../index.style';
import { CustomCheckbox } from '../../../../custom-checkbox';
import { SETTINGS_LOCATION_TYPE, SUCCESS } from '../../../../../v2/services/constants';
import { useEffect, useState } from 'react';
import { CustomIcons, icons } from '../../../../../v2/shared/components/custom-icons';
import { addExemptedDevice, deleteExemptedDevice, getExemptedDevicesList } from '../../../../../util/APIUtils';
import { SELECTED_LOCATION } from '../../../../../constants';
import UnleashProvider from '../../../../../v2/core/unleash/unleashProvider';
import { UnleashService } from '../../../../../v2/services';
import BulkResetDevicePairing from '../bulk-reset-device-pairing';
import ResetDevicePairing from '../reset-device-pairing';

const UserDevicePairing = (props) => {
  const { classes, locationName, unleashProps } = props;
  const locationId = SELECTED_LOCATION.locationId;

  const [addNewDevice, setAddNewDevice] = useState(false);
  const [newDevice, setNewDevice] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [allExemptedDevices, setAllExemptedDevices] = useState([]);

  let featureData = { classes: [classes.level1Box, props.isSuperAdmin ? '' : classes.noBorderBottom], tooltipText: '' };

  useEffect(() => {
    fetchAllDeviceSerialNumbers();
  }, []);

  const fetchAllDeviceSerialNumbers = () => {
    setLoading(true);
    getExemptedDevicesList(locationId)
      .then((response) => {
        if (response && response.status === SUCCESS) {
          let allExemptedDevices = (response.payload || []).map((item) => item.serialNumber);
          setAllExemptedDevices(allExemptedDevices);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onDeleteSerialNumber = (serialNumber) => {
    setLoading(true);
    deleteExemptedDevice(locationId, { serialNumber })
      .then((response) => {
        if (response && response.status === SUCCESS) {
          let exemptedDevices = [...allExemptedDevices];
          const index = exemptedDevices.findIndex((item) => item === serialNumber);
          exemptedDevices.splice(index, 1);
          setAllExemptedDevices(exemptedDevices);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const addNewDeviceSerialNumber = () => {
    setLoading(true);
    addExemptedDevice(locationId, { serialNumber: newDevice })
      .then((response) => {
        if (response && response.status === SUCCESS) {
          let exemptedDevices = [...allExemptedDevices];
          exemptedDevices.push(newDevice);
          setAllExemptedDevices(exemptedDevices);
          setAddNewDevice(false);
          setNewDevice('');
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const UserDeviceToggle = () => (
    <Box
      id="box-wrapper"
      component="div"
      justifyContent="space-between"
      className={featureData.classes.join(' ')}
      display="flex"
    >
      <Box component="div" textAlign="left">
        <Typography className={classes.boxHeading1}>{'User-Device Pairing'}</Typography>
        <Typography id="location-text" className={classes.subHeading1}>
          {props.locationType === SETTINGS_LOCATION_TYPE.FACILITY &&
            `If turned on, learners will be paired to the next device they log into. Once paired with a device, learners will only be able to log into their paired device.`}
          {props.locationType === SETTINGS_LOCATION_TYPE.LEARNER &&
            `If turned on, this learner will be paired to the next device they log into. Once paired with a device, the learner will only be able to log into their paired device.`}
        </Typography>
      </Box>
      <Tooltip title={featureData.tooltipText}>
        <Box display="flex" alignItems="center" component="div">
          {props.messagingStatusLoader && <Loader />}
          <CustomCheckbox
            id="status-checkbox"
            onChange={() => {
              props.updateSettingsState(
                'feature',
                {
                  status: !props.status,
                },
                'features',
                'userDevicePairing',
                'messagingStatusLoader'
              );
            }}
            checked={props.status}
            disabled={featureData.disableSwitch}
          />
        </Box>
      </Tooltip>
    </Box>
  );

  return (
    <Accordion id="accordion" title={'Security'}>
      <Card variant="outlined" className={classes.outlinedComponent}>
        <CardContent
          classes={{
            root: [classes.settingsRoot, classes.noPaddBottom].join(' '),
          }}
        >
          <UnleashProvider
            flagName={UnleashService.FLAGS.RELAX_SECURITY_TOGGLE}
            fallbackComponent={
              //TODO: Remove this fallbackComponent PROP AS PART OF FEATURE FLAG CLEANUP
              props.isSuperAdmin && <UserDeviceToggle />
            }
          >
            <UserDeviceToggle />
          </UnleashProvider>

          {(props.isSuperAdmin || props.isFacilityAdmin) && props.locationType === SETTINGS_LOCATION_TYPE.FACILITY && (
            <BulkResetDevicePairing locationId={props.locationId} locationName={locationName} />
          )}

          {(props.isSuperAdmin || props.isFacilityAdmin || props.isTeacher) &&
            props.locationType === SETTINGS_LOCATION_TYPE.LEARNER && (
              <ResetDevicePairing
                isFlagEnabled={unleashProps.isFlagEnabled()}
                learnerId={props.user.id}
                learnerName={props.user.userName}
              />
            )}

          {props.isSuperAdmin && (
            <UnleashProvider flagName={UnleashService.FLAGS.CLEAR_DEVICE_PAIRING_TOGGLE}>
              {props.locationType == SETTINGS_LOCATION_TYPE.FACILITY && props.isSuperAdmin && (
                <Box
                  id="clear-device-pairing-wrapper"
                  component="div"
                  justifyContent="space-between"
                  className={featureData.classes.join(' ')}
                  paddingLeft="90px !important"
                  display="flex"
                >
                  <Box component="div" textAlign="left">
                    <Typography className={classes.boxHeading1}>{'Clear Paired Device Upon Location Change'}</Typography>
                    <Typography id="location-text" className={classes.subHeading1}>
                      If turned on, a learner's device pairing will be cleared whenever the learner moves into a new location
                      (facility or housing unit). Otherwise, device pairings will only be reset when initiated by an admin.
                    </Typography>
                  </Box>
                  <Tooltip title={featureData.tooltipText}>
                    <Box display="flex" alignItems="center" component="div">
                      {props.clearDevicePairingOnMoveLoading && <Loader />}
                      <CustomCheckbox
                        id="clear-device-pairing-checkbox"
                        onChange={() => {
                          props.updateSettingsState(
                            'feature',
                            {
                              status: !props.clearDevicePairingOnMove.status,
                            },
                            'features',
                            'clearDevicePairingOnMove',
                            'clearDevicePairingOnMoveLoading'
                          );
                        }}
                        checked={props.clearDevicePairingOnMove.status}
                        disabled={!props.status}
                      />
                    </Box>
                  </Tooltip>
                </Box>
              )}
            </UnleashProvider>
          )}
        </CardContent>
        <div className={classes.accoridionDetailsWrapper}>
          <Grid className={classes.boxWrapper} item xs={12}>
            <Grid className={classes.listMaxWith} item sm={6} xs={12}>
              <Box component="div" justifyContent="space-between" alignItems="center" display="flex">
                <Box component="div" textAlign="left">
                  <Typography className={classes.boxHeading1}>Serial Numbers for Exempted Devices</Typography>
                  <Typography className={classes.subHeading1}>
                    Please enter serial numbers of any devices that learners should be able to log into even if they are
                    paired to another device.
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center" component="div">
                  <IconButton
                    onClick={() => {
                      setAddNewDevice(true);
                      console.log(' Inside add new device');
                    }}
                    color="primary"
                    id="add-device-button"
                    aria-label="add device"
                  >
                    <CustomIcons icon={icons.AddCircleIcon} />
                  </IconButton>
                </Box>
              </Box>

              {addNewDevice && (
                <ListItem>
                  <Grid container alignItems="center">
                    <Grid item md={8} xs={8}>
                      <TextField
                        id="add-new-device"
                        label="Device Serial Number"
                        type="text"
                        autoFocus
                        size="small"
                        className={classes.deviceInput}
                        value={newDevice}
                        onChange={(e) => {
                          setNewDevice(e.target.value);
                        }}
                        variant="outlined"
                        required
                      />
                    </Grid>
                    <Grid item md={4} xs={4} container>
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <>
                          <Grid container alignItems="center">
                            <Grid item md={8} xs={8}>
                              <Button
                                variant="contained"
                                disabled={!!!newDevice}
                                onClick={addNewDeviceSerialNumber}
                                color="primary"
                                id="save-button"
                              >
                                Save
                              </Button>
                            </Grid>
                            <Grid item md={4} xs={4}>
                              <Button variant="contained" onClick={() => setAddNewDevice(false)}>
                                Cancel
                              </Button>
                            </Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </ListItem>
              )}

              <Box className={classes.maxItemsScroll} alignItems="center" component="div">
                <List>
                  {allExemptedDevices.map((deviceSerialNumber, index) => (
                    <ListItem>
                      <ListItemText
                        id={`device-serial-number-list-item-${index}`}
                        className={classes.subHeading1}
                        primary={deviceSerialNumber}
                      />
                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() => onDeleteSerialNumber(deviceSerialNumber)}
                          id={`delete-serial-number-list-item-${index}`}
                          aria-label="delete"
                        >
                          <CustomIcons icon={icons.DeleteIcon} />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Grid>
          </Grid>
        </div>
      </Card>
    </Accordion>
  );
};

export default withStyles(styles)(UserDevicePairing);
