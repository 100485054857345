import React from 'reactn';
import { Box, List, ListItem } from "@material-ui/core";
import { DateRangePicker } from 'materialui-daterange-picker';
import { withStyles } from '@material-ui/styles';
import moment from 'moment';
import { Snackbar } from '../../v2/shared/components/MaterialUIComponents';
import styles from './index.styles';
import { MAX_DAYS_ALLOWED, SNACK_BAR_TIMEOUT } from '../../v2/services/constants';
import { CustomIcons, icons } from '../../v2/shared/components/custom-icons';
import { Typography } from '@orijinworks/frontend-commons';

/**
 * @name initiateDataFetch
 * @param {object} range
 * @param {callback} setData
 * @param {callback} setDateRangeVisiblity (optional) only required when needed to toggle off
 * @desc Initiates data fetch either from cache / API endpoint.
 * @return {void}
 */
const initiateDataFetch = (range, dateChangeHandler, setDateRangeVisiblity) => {
    React.setGlobal({
       range 
    }, () => {
        if (dateChangeHandler) {
            dateChangeHandler(range);
        }
        if (setDateRangeVisiblity) {
            setDateRangeVisiblity(false);
        }

    });
};

/**
 * @name getRangeData
 * @param {number} daysCount
 * @desc Prepares range data.
 * @return {object}
 */
const getRangeData = (daysCount) => {
    var d = new Date();
    var _range = daysCount
    d.setDate(d.getDate() - _range);
    React.setGlobal({
        selectedDateRange: daysCount
    });
    return {
        startDate: d.toISOString(),
        endDate: new Date().toISOString()
    };
};

const DaysFilter = (props) => {
    const { classes } = props;
    const [isCustom, setCustom] = React.useState(false);
    const [showDateRange, setDateRangeVisiblity] = React.useState(false);
    const [snackBar, setSnackBar] = React.useState(false);

    const handleDateChange = (range) => {
        if (range.startDate && range.endDate) {
            const daysApart = Math.abs((range.endDate - range.startDate) / (1000 * 60 * 60 * 24));
            if (daysApart > MAX_DAYS_ALLOWED) {
                setSnackBar(true);
            } else {
                setSnackBar(false);
            }
        }
        const dates = {
            startDate: moment.utc(moment(range.startDate).format("YYYY-MM-DD")).toISOString(),
            endDate: moment.utc(moment(range.endDate).format("YYYY-MM-DD")).toISOString(),
        };
        initiateDataFetch(dates, props.dateChangeHandler, setDateRangeVisiblity);
    };

    return (
        <Box
            display="flex"
            position="relative"
            alignItems="center"
            justifyContent="center"
            className={classes.actionBar}>
            <List className={classes.daysSelectionList}>
                <ListItem
                    button
                    onClick={() => {
                        setCustom(false);
                        React.setGlobal({
                            selectedDateRange: 7
                        });
                        initiateDataFetch(getRangeData(7), props.dateChangeHandler);
                    }}
                    className={[
                        classes.daysSelectionItem,
                        React.getGlobal().selectedDateRange === 7 && !isCustom ? classes.activeDay : null
                    ].join(" ")}
                >
                    7 Days
                </ListItem>
                <ListItem
                    onClick={() => {
                        setCustom(false);
                        React.setGlobal({
                            selectedDateRange: 30
                        });
                        initiateDataFetch(getRangeData(30), props.dateChangeHandler);
                    }}
                    button
                    className={[
                        classes.daysSelectionItem,
                        React.getGlobal().selectedDateRange === 30 && !isCustom ? classes.activeDay : null
                    ].join(" ")}
                >
                    30 Days
                </ListItem>
                <ListItem
                    onClick={() => {
                        setCustom(false);
                        React.setGlobal({
                            selectedDateRange: 90
                        });
                        initiateDataFetch(getRangeData(90), props.dateChangeHandler);
                    }}
                    button
                    className={[
                        classes.daysSelectionItem,
                        React.getGlobal().selectedDateRange === 90 && !isCustom ? classes.activeDay : null
                    ].join(" ")}
                >
                    90 Days
                </ListItem>
                <ListItem
                    onClick={() => {
                        setCustom(false);
                        React.setGlobal({
                            selectedDateRange: 365
                        });
                        initiateDataFetch(getRangeData(365), props.dateChangeHandler);
                    }}
                    button
                    className={[
                        classes.daysSelectionItem,
                        React.getGlobal().selectedDateRange === 365 && !isCustom ? classes.activeDay : null
                    ].join(" ")}
                >
                    365 Days
                </ListItem>
                <ListItem
                    onClick={() => {
                        setCustom(true);
                        setDateRangeVisiblity(true); // ONLY FOR DATE RANGE
                    }}
                    button
                    className={[
                        classes.daysSelectionItem,
                        isCustom
                            ? classes.activeDay : null
                    ].join(" ")}
                >
                    Custom
                </ListItem>
            </List>
            <Box
                position="absolute"
                zIndex="999"
                top="0px"
                left={props.dateRangePickerProps.left}
            >
                <DateRangePicker
                    open={showDateRange}
                    closeOnClickOutside={true}
                    maxDate={new Date()}
                    toggle={() => setDateRangeVisiblity(!showDateRange)}
                    onChange={handleDateChange}
                />
            </Box>
            <Snackbar
                open={snackBar}
                autoHideDuration={SNACK_BAR_TIMEOUT}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                onClose={() => setSnackBar(false)}
            >
                <Box className={classes.dateSnackbar}>
                    <CustomIcons icon={icons.WarningIcon} className={classes.warningIcon} />
                    <Typography className={classes.dateAlert} variant='p'>
                        Due to high messaging data volume, we strongly recommend retrieving no more than 1 year of records at a time.
                    </Typography>
                    <CustomIcons onClick={() => setSnackBar(false)} icon={icons.CloseIcon} className={classes.errorIcon}/>
                </Box>
            </Snackbar>
        </Box>
    );
};


export default withStyles(styles)(DaysFilter);
