import React from 'react';

const EntertainmentIcon = (props) => {
  const { width, height, fill } = props;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 16 17" fill="#333">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.22366 7.11412C5.22366 6.74045 5.516 6.43753 5.87662 6.43753H9.61937C9.97998 6.43753 10.2723 6.74045 10.2723 7.11412C10.2723 7.48779 9.97998 7.79071 9.61937 7.79071H5.87662C5.516 7.79071 5.22366 7.48779 5.22366 7.11412Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.22366 9.62359C5.22366 9.24992 5.516 8.947 5.87662 8.947H9.61937C9.97998 8.947 10.2723 9.24992 10.2723 9.62359C10.2723 9.99726 9.97998 10.3002 9.61937 10.3002H5.87662C5.516 10.3002 5.22366 9.99726 5.22366 9.62359Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.379 2.4545C15.2579 2.40333 15.1284 2.37741 14.9979 2.378H5.3751C5.01448 2.378 4.72214 2.68092 4.72214 3.05459C4.72214 3.42826 5.01448 3.73118 5.3751 3.73118H14.6941V3.9618L14.3234 5.89456C14.008 6.03352 13.7189 6.23615 13.4742 6.49431C12.9902 7.00504 12.719 7.69225 12.719 8.4081C12.719 9.12395 12.9902 9.81112 13.4742 10.3218C13.8114 10.6777 14.2331 10.9281 14.6915 11.0516V13.0248L1.30591 13.0248V11.0523C1.7653 10.929 2.18798 10.6784 2.52582 10.3219C3.00984 9.81113 3.28104 9.12394 3.28104 8.4081C3.28104 7.69228 3.00984 7.00507 2.52581 6.49433C2.18797 6.13784 1.76528 5.88722 1.30591 5.76394V3.73118H2.92525C3.28587 3.73118 3.57821 3.42826 3.57821 3.05459C3.57821 2.68092 3.28587 2.378 2.92525 2.378H0.997719C0.733102 2.378 0.479333 2.48693 0.29223 2.6808C0.105121 2.87468 0 3.13764 0 3.41183V6.35364C0 6.72731 0.292339 7.03023 0.652957 7.03023H0.674522C1.0211 7.03819 1.35106 7.18605 1.59405 7.44244C1.83829 7.70016 1.97512 8.04692 1.97512 8.4081C1.97512 8.76929 1.83829 9.11604 1.59404 9.37377C1.35104 9.63018 1.02108 9.77804 0.67455 9.78598H0.652957C0.292339 9.78598 0 10.0889 0 10.4626V13.3442C0 13.6184 0.105156 13.8812 0.292039 14.075C0.479193 14.2689 0.733203 14.378 0.997719 14.378H14.9997C15.2642 14.378 15.518 14.2692 15.7052 14.0752C15.8921 13.8814 15.9974 13.6183 15.9974 13.3442V10.4626C15.9974 10.0889 15.705 9.78598 15.3444 9.78598H15.3254C14.9789 9.77804 14.649 9.63018 14.406 9.3738C14.1618 9.11605 14.0249 8.76926 14.0249 8.4081C14.0249 8.04695 14.1617 7.70019 14.406 7.44245C14.6489 7.1861 14.9789 7.03819 15.3255 7.03023H15.347C15.7077 7.03023 16 6.72731 16 6.35364V3.41183C16 3.27536 15.9739 3.14055 15.9237 3.0151L15.9233 3.01427C15.8726 2.88822 15.7985 2.7741 15.7054 2.67829L15.7049 2.67781C15.6115 2.58187 15.5008 2.50596 15.379 2.4545ZM14.3234 5.89456L14.6941 3.9618V5.76394C14.5674 5.79794 14.4435 5.84164 14.3234 5.89456Z"
        fill={fill}
      />
    </svg>
  );
};

EntertainmentIcon.defaultProps = {
  width: 16,
  height: 17,
  fill: 'inherit',
};

export default EntertainmentIcon;
