import { useCallback, useEffect, useState } from 'react';
import { deleteAd, fetchActiveAds, toggleAdsVisibility, uploadAd } from './services/manage-ads.service';

/**
 * Custom hook to manage ads
 *
 * @returns {object} - Object containing the following properties:
 * - isEnabled: boolean - Whether the ads are enabled
 * - showAdsToggleLoader: boolean - Whether the loader should be shown when toggling ads visibility
 * - adsList: array - List of active ads
 * - error: string - Error message
 * - handleAdsVisibility: function - Function to handle ads visibility toggle
 * - handleAdsUpload: function - Function to handle ad upload
 * - hasAds: function - Function to check if there are any ads
 * - handleAdDelete: function - Function to handle ad deletion
 * - toggleError: function - Function to toggle error message
 */
const useManageAdsHook = () => {
  const [isEnabled, setIsEnabled] = useState(true);
  const [showAdsToggleLoader, setShowAdsToggleLoader] = useState(false);
  const [adsList, setAdsList] = useState([]);
  const [error, setError] = useState(null);

  /**
   * Effect to fetch active ads
   */
  useEffect(() => {
    const getActiveAds = async () => {
      try {
        const response = await fetchActiveAds();
        const { advertisements, featureStatus } = response;
        setIsEnabled(featureStatus);
        setAdsList(advertisements);
      } catch (error) {
        console.error(error);
        toggleError('Error fetching active ads. Please try again later.');
      }
    };

    getActiveAds();
  }, []);

  /**
   * Function to handle ads visibility toggle
   *
   * @param {object} event - Event object
   */
  const handleAdsVisibility = async (event) => {
    try {
      const status = event.target.checked;
      setShowAdsToggleLoader(true);

      await toggleAdsVisibility(status);
      setIsEnabled(status);
    } catch (error) {
      console.error(error);
      toggleError('Error toggling ads visibility. Please try again later.');
    } finally {
      setShowAdsToggleLoader(false);
    }
  };

  /**
   * Function to handle ad upload
   *
   * @param {object} file - File object
   * @param {function} cb - Callback function
   */
  const handleAdsUpload = async (file, cb) => {
    try {
      const response = await uploadAd(file);
      setAdsList((prevAds) => [...prevAds, response]);
    } catch (error) {
      console.error(error);
      toggleError('Error uploading ad. Please try again later.');
      cb();
    }
  };

  /**
   * Function to check if there are any ads
   */
  const hasAds = useCallback(() => {
    return adsList.length > 0;
  }, [adsList]);

  /**
   * Function to handle ad deletion
   *
   * @param {object} ad - Ad object
   * @param {function} cb - Callback function
   */
  const handleAdDelete = async (ad, cb) => {
    try {
      await deleteAd(ad.id);
      setAdsList((prevAds) => prevAds.filter((item) => item.id !== ad.id));
      cb();
    } catch (error) {
      console.error(error);
      toggleError('Error deleting ad. Please try again later.');
      cb(error);
    }
  };

  /**
   * Function to toggle error message
   *
   * @param {string} message - Error message
   */
  const toggleError = (message) => {
    setError(message);
  };

  return {
    isEnabled,
    showAdsToggleLoader,
    adsList,
    error,
    handleAdsVisibility,
    handleAdsUpload,
    hasAds,
    handleAdDelete,
    toggleError,
  };
};

export default useManageAdsHook;
