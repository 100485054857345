import React, { Component, Suspense } from 'reactn';
import {
    withStyles,
    Button,
    Grid,
    Box,
    IconButton
} from '@material-ui/core';
import Datatable, { MTableToolbar } from 'material-table'
import { FnFMessagingService } from '../../FnFMessagingService';
import moment from "moment";

// Icons
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import EditIcon from '@material-ui/icons/Edit'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import LastPageIcon from '@material-ui/icons/LastPage'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import FilterListIcon from '@material-ui/icons/FilterList'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import CustomDatePicker from "../../../../users/datatable/customDatePicker";

import Hoverable from '../../../../hoverable';
import { Loader, Typography } from '@orijinworks/frontend-commons';
import styles from './connections.style';
import { formatPhoneNumber } from '../../../../../util/Helpers';
import MessageHistory from '../messages/message-history';
import Menu from '../../../menu/index';
import { Modal } from "@orijinworks/frontend-commons"
import { CustomIcons, icons } from '../../../../../v2/shared/components/custom-icons/CustomIcons';
import { deleteFriendsAndFamilyContact } from '../../../../../util/APIUtils';
import { DELETE_FRIENDS_AND_FAMILY_CONTACT_ERROR, SUCCESS } from '../../../../../v2/services/constants';

class Connections extends Component {
    tableRef = React.createRef();
    FnFMessagingService = new FnFMessagingService();
    tableColumns = [
        {
            title: "Learner Name",
            field: "learnerName",
            cellStyle: {
                minWidth: 150,
            },
            render: (rowData) => (
                <Hoverable title={rowData.learner.name}>
                    <span className={this.props.classes.capitalize}>
                        {rowData.learner.name}
                    </span>
                </Hoverable>
            )
        },
        {
            title: "Doc ID",
            field: "learnerDocId",
            width: '10%',
            cellStyle: {
                minWidth: 100,
            },
            render: (rowData) => (
                <Hoverable title={rowData.learner.docId}>{rowData.learner.docId}</Hoverable>
            )
        },
        {
            title: "Contact Name",
            field: "contactName",
            cellStyle: {
                minWidth: 150
            },
            render: (rowData) => (
                <Hoverable title={rowData.contact.name}>
                    <span className={this.props.classes.capitalize}>
                        {rowData.contact.name}
                    </span>
                    <p className={this.props.classes.capitalize}>
                        {rowData.contact.relationship}
                    </p>
                </Hoverable>
            )
        },
        {
            title: "Contact Email",
            field: "contactRegisteredEmail",
            width: '15%',
            cellStyle: {
                overflowWrap: 'break-word',
            },
            cellClassName: this.props.classes.customEmailWidth,
            render: (rowData) => (
                <Hoverable title={rowData.contact.registeredEmail}>
                    <span>
                        {rowData.contact.registeredEmail}
                    </span>
                </Hoverable>
            )
        },
        {
            title: "Relationship",
            field: "relationship",
            cellStyle: {
                minWidth: 100,
            },
            filtering: false,
            render: (rowData) => (
                <Hoverable title={rowData.contact.relationship}>
                    <span className={this.props.classes.capitalize}>
                        {rowData.contact.relationship}
                    </span>
                </Hoverable>
            ),
            hidden: true
        },
        {
            title: "Date Created",
            field: "createdDate",
            defaultSort: 'desc',
            type: "date",
            width: '10%',
            cellStyle: {
                overflowWrap: 'break-word',
            },
            render: (rowData) => (
                <Hoverable title={moment(rowData.createdDate).format("MM-DD-YYYY")}>
                    {moment(rowData.createdDate).format("MM-DD-YYYY")}
                </Hoverable>
            ),
            cellStyle: {
                minWidth: 250
            }
        },
        {
            title: "Phone Number",
            field: "contactPhoneNumber",
            cellStyle: {
                minWidth: 200
            },
            render: (rowData) => (
                <Hoverable title={rowData.contact.phone}>{formatPhoneNumber(rowData.contact.phone)}</Hoverable>
            )
        },
        {
            title: "Connection Status",
            field: "statusesAdmin",
            sorting: false,
            cellStyle: {
                minWidth: 200
            },
            render: (rowData) => {
                const status = this.FnFMessagingService.getConnectionStatus(rowData.status)
                const statusConstants = this.FnFMessagingService.FNF_RELATIONSHIP_STATUS
                const colorClass = status === statusConstants.CONNECTED ? "success"
                    : [statusConstants.BLOCKED, statusConstants.REJECTED].includes(status) ? "danger"
                        : status === statusConstants.REQUESTED ? "warning" : ""
                return (
                    <Hoverable title={status}>
                        <span className={[this.props.classes.capitalize, this.props.classes[colorClass]].join(" ")}>
                            {status.toLowerCase()}
                        </span>
                    </Hoverable>
                )
            },
            lookup: {
                'REQUESTED': 'Requested',
                'CONNECTED': 'Connected',
                'REJECTED': 'Rejected',
                'BLOCKED': 'Blocked'
            }
        },
        {
            field: "actions",
            sorting: false,
            filtering: false,
            width: '10%',
            cellStyle: {
                minWidth: 300
            },
            render: (rowData) => {
                const options = [];
                const statusAction = this.FnFMessagingService.getStatusAction(rowData.status.admin);
                const { contact, learner } = rowData;
                if (statusAction.show) {
                    options.push({
                        title: statusAction.label,
                        onClick: () => this.onStatusClick(rowData, statusAction)
                    });
                }

                if (rowData.status && rowData.status.admin !== 'BLOCKED') {
                    options.push({
                        title: 'Delete Connection',
                        onClick: () => {
                            this.setState({ selectedContactRelationship: rowData });
                            this.toggleConfirmationModal();
                        }
                    });
                }

                options.push({
                    title: "View Conversation",
                    onClick: () => {
                        this.setState({
                            selectedRow: {
                                contactId: contact.personId,
                                learnerId: learner.personId        
                            }
                        },()=>{
                            this.messageRef.handleClickOpen()
                        })
                    }
                });

                return (
                    <Hoverable>
                        <Menu options={[...options]} />
                        <MessageHistory
                            messageId={rowData.messageId}
                            locationId={this.props.locationId}
                            ref={ref => this.messageRef = ref}
                            learnerPersonId={this.state.selectedRow.learnerId}
                            contactPersonId={this.state.selectedRow.contactId}
                            openFromConnections={this.state.openFromConnections}
                            refreshTable={this.refreshTable}
                            timezoneName={this.props.timezoneName}
                            closeMessageHistory={() => this.setState({ openFromConnections: false })}
                        />
                    </Hoverable>
                )
            }
        }
    ]

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            deleteError: '',
            connections: [],
            totalCount: 0,
            pageNo: 0,
            rowsPerPage: 20,
            filters: {
                learnerName: "",
                learnerDocId: "",
                contactName: "",
                contactRegisteredEmail: "",
                contactPhoneNumber: "",
                relationships: [],
                statusesAdmin: []
            },
            allowedFilters: ["learnerName", "learnerDocId", "contactName", "contactRegisteredEmail", "contactPhoneNumber", "statusesAdmin", "createdDate"],
            openFromConnections: false,
            selectedContactRelationship: null,
            selectedRow: {}
        }
    }

    refreshTable = () => {
        this.tableRef && this.tableRef.onQueryChange(this.tableRef.state.query);
    }
    getFilters = (query) => {
        let filters = { ...this.state.filters }
        for (let i = 0; i < query.filters.length; i++) {
            let item = query.filters[i]
            if (this.state.allowedFilters.includes(item.column.field)) {
                let value = item.value
                if (Array.isArray(value) && value.length > 0) {
                    value = [...item.value]
                    let index = value.findIndex((a) => a === "CONNECTED")
                    if (index >= 0) value[index] = "ACCEPTED"
                } else if (item.column.type === "date") {
                    value = moment(item.value).format("YYYY-MM-DD")
                }
                filters[item.column.field] = value
            }
        }
        return filters
    }

    getSortBy = (query) => {
        let sortBy
        if (query.orderBy && query.orderDirection) {
            const field = query.orderBy.field
            switch (field) {
                case 'learnerName':
                    sortBy = `learner-name:${query.orderDirection}`
                    break
                case 'learnerDocId':
                    sortBy = `doc-id:${query.orderDirection}`
                    break
                case 'contactName':
                    sortBy = `contact-name:${query.orderDirection}`
                    break
                case 'contactRegisteredEmail':
                    sortBy = `contact-email:${query.orderDirection}`
                    break
                case 'relationship':
                    sortBy = `relationship:${query.orderDirection}`
                    break
                case 'createdDate':
                    sortBy = `created-date:${query.orderDirection}`
                    break
                case 'contactPhoneNumber':
                    sortBy = `contact-phone:${query.orderDirection}`
                    break
                case 'statusesAdmin':
                    sortBy = `status-admin:${query.orderDirection}`
                    break
                default:
            }
        }
        return sortBy
    }

    getFnFConnections = (query) => {
        this.setColumnSort(query.orderBy, query.orderDirection)
        this.setState({
            showEmptyDataSourceMessage: false
        });
        return new Promise((resolve, reject) => {
            const pBody = this.getFilters(query)
            const sortBy = this.getSortBy(query)
            this.FnFMessagingService.getConnections(this.props.locationId, query.pageSize, sortBy, query.page + 1, pBody).then((data) => {
                const { payload } = data
                resolve({
                    data: payload && payload.relationships,
                    page: query.page,
                    totalCount: payload && payload.totalRelationshipCount || query.totalCount
                })
                this.setState({
                    showEmptyDataSourceMessage: true
                });
            });
        })
    }

    setColumnSort = (orderBy, orderDirection) => {
        if (orderBy && orderDirection) {
            this.tableColumns = this.tableColumns.map((item, i) => {
                if (item.field !== orderBy.field) {
                    item.defaultSort = ""
                } else {
                    item.defaultSort = orderDirection
                }
                return item
            }).slice()
        }
    }

    onStatusClick = (rowData, statusAction) => {
        this.setGlobal({ isLoading: true });
        this.FnFMessagingService.changeConnectionStatus(
            this.props.locationId,
            {
                status: statusAction.value.toUpperCase(),
                learnerPersonId: rowData.learner.personId,
                contactPersonId: rowData.contact.personId
            }
        ).then(data => {
            this.setGlobal({
                isLoading: false
            });
            this.refreshTable();
        })
    }

    toggleConfirmationModal = () => this.modalRef.current && this.modalRef.current.toggleModal();

    deleteContact = async () => {
        try {
            const { selectedContactRelationship } = this.state;
            const { status } = await deleteFriendsAndFamilyContact(selectedContactRelationship.relationshipId);
            if (status === SUCCESS) {
                this.toggleConfirmationModal();
                this.refreshTable();
            } else {
                this.setState({ deleteError: DELETE_FRIENDS_AND_FAMILY_CONTACT_ERROR });
            }
        } catch (e) {
            this.setState({ deleteError: DELETE_FRIENDS_AND_FAMILY_CONTACT_ERROR });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <Suspense fallback={<Loader isLoading={true} />}>
                <div style={{ padding: '1.5rem' }}>
                    <Grid container item>
                        <Datatable
                            tableRef={(ref) => this.tableRef = ref}
                            onChangePage={(pageNo) => this.setState({ pageNo })}
                            onChangeRowsPerPage={(rowsPerPage) => this.setState({ rowsPerPage })}
                            title="Connections"
                            data={this.getFnFConnections}
                            columns={this.tableColumns}
                            icons={{
                                Search: SearchIcon,
                                ResetSearch: CloseIcon,
                                FirstPage: FirstPageIcon,
                                LastPage: LastPageIcon,
                                NextPage: NavigateNextIcon,
                                PreviousPage: NavigateBeforeIcon,
                                Filter: FilterListIcon,
                                SortArrow: ArrowDownwardIcon,
                                Export: SaveAltIcon,
                                Edit: EditIcon
                            }}
                            components={{
                                Toolbar: props => (
                                    <div>
                                        <MTableToolbar {...props} />
                                    </div>
                                )
                            }}
                            options={{
                                showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                                search: false,
                                sorting: true,
                                thirdSortClick: false,
                                pageSize: this.state.rowsPerPage,
                                filtering: true,
                                actionsColumnIndex: 0,
                                pageSizeOptions: [20, 50, 100],
                                tableLayout: "fixed"
                            }}
                        />
                    </Grid>
                </div>
                    <Modal
                        ref={this.modalRef}
                        isSnackBarShown={false}
                        cancelBtnText="Cancel"
                        actionBtnText="Delete"
                        id="confirmation-modal"
                        labelledBy="test"
                        onClose={() => this.toggleConfirmationModal()}
                        onContinue={() => this.deleteContact()}
                        modalType={{ type: 'confirmation', status: 'danger' }}
                        Icon={<CustomIcons icon={icons.ContactsIcon} />}
                        styles={{
                            mainWrapperStyles: { height: 'auto' },
                        }}
                    >
                        <>
                            {this.state.deleteError &&
                                <Box id="error-box" className={classes.errorWrapper}>
                                    <Box
                                        display="flex"
                                        alignItems={"center"}
                                        flex={1}>
                                        <CustomIcons icon={icons.ErrorIcon} className={classes.errorIcon} />
                                        <Typography>{this.state.deleteError}</Typography>
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => this.setState({ deleteError: '' })}>
                                            <CustomIcons icon={icons.CloseIcon} className={classes.closeIcon} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            }
                            <Typography variant="h3">
                                Are you sure you want to delete this connection?
                            </Typography>

                            <Typography variant="body1">
                                This will allow the learner to submit a new contact request.
                            </Typography>

                            <Box display="flex" className={classes.contactWrapper} mt={4}>
                                <Typography variant="body1" className={classes.contactHeader}>Learner</Typography>
                                <Box mx={1}>
                                    <CustomIcons icon={icons.ArrowRightIcon} className={classes.arrowIconColor} />
                                </Box>
                                <Typography variant="body1" className={classes.contactHeader}>Contact</Typography>
                            </Box>
                            <Box display="flex" className={classes.namesWrapper} mt={1}>
                                <Typography variant="body1" className={classes.contactText}>{this.state.selectedContactRelationship && this.state.selectedContactRelationship.contact.name}</Typography>
                                <Typography variant="body1" className={classes.contactText}>{this.state.selectedContactRelationship && this.state.selectedContactRelationship.learner.name}</Typography>
                            </Box>

                        </>
                    </Modal>

            </Suspense>
        );
    };
};

export default withStyles(styles)(Connections);