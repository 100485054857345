import React, { Component, forwardRef, Suspense } from 'reactn';
import Datatable, { MTableToolbar } from 'material-table';
// import LocationSelect from '../locationDropdown';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import FilterListIcon from '@material-ui/icons/FilterList';
import Paper from '@material-ui/core/Paper';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import RefreshIcon from '@material-ui/icons/Refresh';
import RoomServiceIcon from '@material-ui/icons/LibraryAdd';
import AssignmentReturnIcon from '@material-ui/icons/AssignmentReturn';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LocalActivityOutlinedIcon from '@material-ui/icons/LocalActivityOutlined';
import {
  Zoom,
  Typography,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Dialog,
  Table,
  TableBody,
  Snackbar,
  TableCell,
  TableHead,
  TableRow,
  Box,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  listUsers,
  listLocations,
  getPathFromLocation,
  addBalance,
  deductBalance,
  getUserFeatureStatus,
  approveFRRegistrationRequest,
  getLocationFeatureStatus,
  resetFacialAuthentication,
  getUsers,
} from '../../../../util/APIUtils';
import {
  setUsers,
  USER_CONTEXT,
  setLocations,
  ASSIGNMENT_TYPE,
  MANAGE_BALANCE_TYPE,
  HOUSING_UNITS,
  LOCATIONS,
} from '../../../../constants';
import { Loader, CustomTheme, SnackBar } from '@orijinworks/frontend-commons';
import LayersClearIcon from '@material-ui/icons/LayersClear';
import DeleteIcon from '@material-ui/icons/Delete';
import StarsIcon from '@material-ui/icons/Stars';
import { ManagementService } from '../../../../components/management/management.service';
import { isPermitted, PermissionControl } from '../../../../components/permission-control/permission-control';
import ConfirmationModal from '../../../../components/confirmation-modal';
import { UserIcon } from '../../../../components/UserIcon';
import { convertToID, parseTimeToNewZone } from '../../../../components/users/learner/transcript/utils';
import CustomDatePicker from '../../../../components/users/datatable/customDatePicker';
import ResponseStatusModal from '../../../../components/responseStatusModal';
import AddNewLearnerGroupDialog from '../../../../components/management/staff/add-new-learner-group-dialog';
import { CustomIcons, icons } from '../custom-icons';
import { withUnleash } from '../../../core/unleash';
import { UnleashService } from '../../../services';
import { theme } from '../../../../v2/core/containers/theme';
import { withUnleashClient } from '../../../core/unleash';
import ResetBioauthModal from '../reset-bioauth-modal/reset-bioauth-modal';

const CreateUserDialog = React.lazy(() => import('../../../../components/users/createUser/createUser'));
const BulkUserDialog = React.lazy(() => import('../../../../components/users/bulkusers'));
const NewUserEdit = React.lazy(() => import('../../../../components/users/newUserEdit'));
const BulkEditPassword = React.lazy(() => import('../../../../components/users/bulkEditPassword'));
const BulkEditLocation = React.lazy(() => import('../../../../components/users/bulkEditLocation'));
const BulkDisable = React.lazy(() => import('../../../../components/users/bulkDisable'));
const BulkDisableEntertainment = React.lazy(() => import('../../../../components/users/bulkDisableEntertainment'));
const BulkDisableFriendAndFamily = React.lazy(() => import('../../../../components/users/bulkFriendAndFamily'));
const BulkServiceProvision = React.lazy(() => import('../../../../components/users/bulkServiceProvision'));
const PathAssginmentToUser = React.lazy(() => import('../../../../components/PathAssginmentToUser'));
const PathAssignmentConfirmationModal = React.lazy(() => import('../../../../components/PathAssignmentConfirmationModal'));
const ManageBalance = React.lazy(() => import('../../../../components/manage-balance/manage-balance'));
const ResponseBox = React.lazy(() => import('../../../../components/response-box'));
const BulkUserCard = React.lazy(() => import('../../../../components/bulk-user-card/bulk-user-card'));
const Hoverable = React.lazy(() => import('../../../../components/hoverable'));

function createData(name, provision, sso) {
  return { name, provision, sso };
}

const rows = [
  createData('Opigno', 'true', 'true'),
  createData('Kolibri', 'true', 'true'),
  createData('Paxen', 'true', 'true'),
  createData('Sprokit', 'true', 'true'),
  createData('National Corrections Library', 'true', 'false'),
];

const userAndCameraIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 32 29">
    <g fill="none" fill-rule="evenodd">
      <g fill={CustomTheme.palette.primary.main} fill-rule="nonzero">
        <g>
          <path
            d="M12.12 12.12c3.347 0 6.06-2.713 6.06-6.06 0-3.347-2.713-6.06-6.06-6.06-3.347 0-6.06 2.713-6.06 6.06.006 3.345 2.715 6.054 6.06 6.06z"
            transform="translate(-1877 -650) translate(1877.8 650.724)"
          />
          <g transform="translate(-1877 -650) translate(1877.8 650.724) translate(18.71 16.15)">
            <circle cx="5.74" cy="5.73" r="2.15" />
            <path d="M11.12 1.34H8.55L7.29 0H4.16L2.93 1.34H.35c-.193 0-.35.157-.35.35v8.08c.005.19.16.34.35.34h10.77c.19 0 .345-.15.35-.34V1.69c0-.193-.157-.35-.35-.35zM5.74 9.09c-1.365.004-2.597-.816-3.122-2.076-.524-1.26-.236-2.712.73-3.677.964-.965 2.416-1.253 3.676-.729 1.26.525 2.08 1.757 2.076 3.122 0 1.856-1.504 3.36-3.36 3.36z" />
          </g>
          <path
            d="M16.93 15.79c-1.575-.391-3.188-.606-4.81-.64-4 0-12.12 2-12.12 6v3h15.71v-6.31c.006-.854.472-1.638 1.22-2.05z"
            transform="translate(-1877 -650) translate(1877.8 650.724)"
          />
        </g>
      </g>
    </g>
  </svg>
);

const resetFAIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="24"
    fill={CustomTheme.palette.purple.dark}
    height="24"
    viewBox="0 0 172 172"
  >
    <g
      fill={CustomTheme.palette.purple.dark}
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g fill={CustomTheme.palette.purple.dark}>
        <path d="M86,129c-16.48333,0 -25.8,-8.6 -26.51667,-9.31667l10.03333,-10.03333c0,0 5.73333,5.01667 16.48333,5.01667c10.75,0 16.48333,-5.01667 16.48333,-5.01667l10.03333,10.03333c-0.71667,0.71667 -10.03333,9.31667 -26.51667,9.31667zM107.5,57.33333h14.33333v21.5h-14.33333zM50.16667,57.33333h14.33333v21.5h-14.33333zM78.83333,100.33333h-7.16667v-14.33333h7.16667v-28.66667h14.33333v28.66667c0,7.88333 -6.45,14.33333 -14.33333,14.33333zM150.5,57.33333h-14.33333v-14.33333c0,-4.3 -2.86667,-7.16667 -7.16667,-7.16667h-14.33333v-14.33333h14.33333c12.18333,0 21.5,9.31667 21.5,21.5zM35.83333,57.33333h-14.33333v-14.33333c0,-12.18333 9.31667,-21.5 21.5,-21.5h14.33333v14.33333h-14.33333c-4.3,0 -7.16667,2.86667 -7.16667,7.16667zM129,150.5h-14.33333v-14.33333h14.33333c4.3,0 7.16667,-2.86667 7.16667,-7.16667v-14.33333h14.33333v14.33333c0,12.18333 -9.31667,21.5 -21.5,21.5zM57.33333,150.5h-14.33333c-12.18333,0 -21.5,-9.31667 -21.5,-21.5v-14.33333h14.33333v14.33333c0,4.3 2.86667,7.16667 7.16667,7.16667h14.33333z"></path>
      </g>
    </g>
  </svg>
);

class Users extends Component {
  USERS_COPY = [];
  NO_LOCATION = -1;
  _managementService = new ManagementService();
  columns1 = [];
  tableRef = React.createRef();
  constructor(props) {
    super(props);
    this.columns1 = [
      {
        field: 'docId',
        title: 'DOC ID',
        render: (rowData) => <Hoverable title={rowData.docId}>{rowData.docId}</Hoverable>,
      },
      {
        field: 'displayName',
        title: 'Full Name',
        render: (rowData) => (
          <Hoverable title={rowData.displayName}>
            <Button id={`learner-btn-${rowData.personId}`} color="primary" onClick={() => this.onRedirect(rowData)}>
              <UserIcon style={{ marginRight: 6 }} /> {rowData.displayName}
            </Button>
          </Hoverable>
        ),
      },
      {
        field: 'userName',
        title: 'User Name',
        render: (rowData) => <Hoverable title={rowData.userName}>{rowData.userName}</Hoverable>,
      },
      {
        field: this.props.unleashProps.isFlagEnabled() ? 'locationName' : 'assignedLocationName',
        title: 'Assigned Location',
        render: (rowData) => <Hoverable title={rowData.displayName}>{rowData.locationName}</Hoverable>,
      },
      {
        field: this.props.unleashProps.isFlagEnabled() ? 'frstatus' : 'frStatus',
        title: 'Biometric Authentication',
        render: (rowData) => {
          return this.getReRegisterUserStatus(rowData) ? (
            <div
              style={{ color: theme.palette.text.navy, cursor: 'pointer', display: 'flex' }}
              onClick={this.openFRRequestConfirm}
              data-person-id={rowData.personId}
              data-request-id={
                this.getReRegisterUserStatus(rowData)
                  ? rowData.frreregistrationrequestkey
                  : rowData.frReRegistrationRequestKey
              }
            >
              {userAndCameraIcon}
              <span style={{ lineHeight: 1, marginLeft: 5 }}>Re - Registration</span>
            </div>
          ) : this.props.unleashProps.isFlagEnabled() ? (
            rowData.frstatus
          ) : (
            rowData.frStatus
          );
        },
        lookup: {
          'N/A': 'N/A',
          Registered: 'Registered',
          'Not Registered': 'Not Registered',
          'Re-registration requested': 'Re - registered',
        },
      },
      {
        type: 'date',
        dateSetting: { locale: 'en-US' },
        field: 'modifiedDate',
        title: 'Date Modified',
        filterComponent: (props) => <CustomDatePicker {...props} />,
        render: (rowData) => (
          <Hoverable title={rowData.modifiedDate}>
            {rowData.modifiedDate
              ? parseTimeToNewZone(rowData.modifiedDate.split('.')[0], this.state.timezoneName, 'MM/DD/YYYY hh:mm:ss A')
              : 'N/A'}
            { }
          </Hoverable>
        ),
        cellStyle: {
          minWidth: 150,
        },
      },
      {
        field: 'createdDate',
        title: 'Created Date',
        hidden: true,
      },
      {
        field: 'active',
        title: 'Status',
        default: 'active',
        defaultSort: 'asc',
        render: (rowData) => (
          <Hoverable title={rowData.active}>
            <p
              id={`learner-status-${rowData.personId}`}
              style={{ color: rowData.active === 'active' ? 'black' : theme.palette.text.red }}
            >
              {rowData.active}
            </p>
          </Hoverable>
        ),
        lookup: { active: 'active', deactivated: 'inactive' },
      },
    ];
    this.state = {
      timezoneName: 'UTC',
      pageNo: 0,
      rowsPerPage: 50,
      incentiveEngineVisibile: false,
      orderBy: {
        columnIndex: 7,
        orderDirection: 'asc',
      },
      response: {
        heading: '',
        isOpen: false,
        data: null,
      },
      responseData: {
        isOpen: false,
        title: '',
        status: '',
        message: '',
      },
      manageBalance: {
        isOpen: false,
        formData: [],
      },
      bulkUserCard: {
        isOpen: false,
      },
      assignmentType: ASSIGNMENT_TYPE.ASSIGNE,
      selectedLocation: null,
      selectedLocationName: null,
      userEditOpen: false,
      bulkDisableOpen: false,
      bulkDisableEntertainmentOpen: false,
      bulkUserEditOpen: false,
      bulkUserLocationOpen: false,
      bulkUserServiceOpen: false,
      selectedUser: [],
      USERS: [],
      dataLoading: false,
      showInfo: false,
      openPathAssignmentModal: false,
      paths: [],
      selectedUsersForPathAssignment: [],
      confirmationModal: false,
      deletedUsers: [],
      isFRRequestConfirmOpen: false,
      fRRequestPersonId: null,
      fRRequestId: null,
      showErrorMsg: false,
      errorMsg: '',
      resetFaceAuthUsers: [],
      openResetFaceAuthConfirmation: false,
      openLearnerGroup: false,
      snackbarOpen: false,
      snackBarMessage: '',
      snackBarType: 'error',
      resetBioAuthDialog: {
        isOpen: false,
        successfulUsers: [],
        failedUsers: [],
      },
    };
    this.handleLocationSelect.bind(this);
    this.onClosePathAssignmentModal = this.onClosePathAssignmentModal.bind(this);
    this.closeConfirmationModal = this.closeConfirmationModal.bind(this);
  }

  componentDidMount() {
    this.setState({
      selectedLocation: this.props.location,
      selectedLocationName: this.props.locationName,
    });
    this.fetchUsers(this.props.location);
    this.fetchPath(this.props.location);
    this.getIncentiveEngineStatus(this.props.location);
    this.getLocationTimezone();
  }

  /**
   * @name getLocationTimezone
   * @desc Fetches location timezone from saved locations and props active locations.
   * @return {void}
   */
  getLocationTimezone = () => {
    const { active } = this.props.locationMeta;
    let activeLocation = {};
    if (this.props.locationMeta.type === 'hu') {
      activeLocation = HOUSING_UNITS.find((location) => location.locationId === active);
    } else {
      activeLocation = LOCATIONS.find((location) => location.locationId === active);
    }
    const locationAttribute = (activeLocation ? activeLocation.attributes : []).find(
      (attr) => attr.attributeName === 'TimeZone'
    );
    if (locationAttribute) {
      this.setState({
        timezoneName: locationAttribute.attributeValue,
      });
    }
  };

  /**
   *
   * @param {*} rowData single learner data retrived from getUsers calls
   * @desc This is the utility function to get the re-register user status w.r.t feature flag
   * @returns {boolean}
   */
  getReRegisterUserStatus(rowData) {
    const { unleashProps } = this.props;
    if (unleashProps.isFlagEnabled()) {
      return rowData.frreregistrationrequestkey;
    }
    return rowData.frReRegistrationRequestKey;
  }

  onRedirect = (rowData) => {
    this.setGlobal({
      selectedUser: rowData.personId,
      user: rowData,
      openProfileView: false,
      selectedLearnerLocationId: rowData.assignedLocationId,
      fetchUsers: () => this.fetchUsers(this.props.location),
    });
    this.props.history.push(`/users/${rowData.personId}`);
  };

  exportUsersList = async (columns, data, fileName) => {
    this.setGlobal({
      isLoading: true,
    });
    try {
      let csvContent = '';
      const columnString = columns.map((column) => column.title).join(',');
      // APPENDING COLUMNS
      csvContent += columnString + '\r\n';
      // CONSTRUCTING ROWS
      // BASED UPON COLUMNS KEY
      data.forEach((item) => {
        const rowString = columns
          .map((column) => {
            if (column.field) {
              if (item[column.field] || typeof item[column.field] === 'boolean') {
                if (column.field === 'modifiedDate' && item[column.field]) {
                  return (
                    '"' +
                    parseTimeToNewZone(item[column.field].split('.')[0], this.state.timezoneName, 'MM/DD/YYYY hh:mm:ss A') +
                    '"'
                  );
                }
                return '"' + (item[column.field].toString() || '').split('"').join('""') + '"';
              }
              return `N/A`;
            }
          })
          .join(',');
        csvContent += rowString + '\r\n';
      });
      const universalBOM = '\uFEFF';
      let csvData = 'data:text/csv;charset=utf-8,' + encodeURIComponent(universalBOM + csvContent);
      this.exportFile(fileName, csvData);
      this.setGlobal({
        isLoading: false,
      });
    } catch (e) {
      console.error(e);
      this.setGlobal({
        isLoading: false,
      });
    }
  };

  /**
   * @name exportFile
   * @param {string} fileName
   * @param {string} csvContent
   * @desc Handle's DOM file export functionality.
   * @return {void}
   */
  exportFile = (fileName, csvContent) => {
    var link = document.createElement('a');
    link.setAttribute('href', csvContent);
    link.setAttribute('download', `${fileName}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click(); // This will download the data file named "${fileName}.csv".
    document.body.removeChild(link);
  };

  /**
   * @name setOrdering
   * @param {number} columnIndex
   * @param {string} direction
   * @desc Sets ordering so that it persist the state.
   * @return {void}
   */
  setOrdering = (columnIndex, direction) => {
    // REMOVING SORT PROPERTIES FROM
    // PREVIOUS COLUMN
    const previousSort = this.state.orderBy;
    const previousSortedColumn = this.columns1[previousSort.columnIndex];
    delete previousSortedColumn.default;
    delete previousSortedColumn.defaultSort;
    if (columnIndex < 0) {
      // NO NEW SORT REQUIRED
      this.forceUpdate();
      return;
    }
    // SETTING COLUMN PROPERTY
    // AVOIDING ASSIGNMENT DUE TO PASS-BY-REF. OPERATION
    let column = this.columns1[columnIndex];
    column['default'] = column.field;
    column['defaultSort'] = direction;
    // SETTING STATE FOR NEW SORT
    this.setState({
      orderBy: {
        columnIndex,
        direction,
      },
      USERS: this.tableRef.dataManager.sortedData,
    });
  };

  /**
   * @name getIncentiveEngineStatus
   * @param {number} locationId
   * @desc Fetches status of Incentive Engine.
   * @return {void}
   */
  getIncentiveEngineStatus = async (locationId) => {
    try {
      const feature = await getLocationFeatureStatus(locationId, 'Incentive_Engine');
      this.setState({
        incentiveEngineVisibile: feature.status,
      });
    } catch (e) {
      console.error(e);
    }
  };

  componentDidUpdate() {
    if (this.props.location === this.state.selectedLocation || this.props.locationName === this.state.selectedLocationName) {
      return;
    }
    this.setState({
      selectedLocation: this.props.location,
      selectedLocationName: this.props.locationName,
    });
    this.setGlobal({
      isLoading: true,
    });
    this.fetchUsers(this.state.selectedLocation);
    this.fetchPath(this.state.selectedLocation);
  }

  handleBulkUserCardDialog = (isOpen) => {
    this.setState({
      bulkUserCard: {
        ...this.state.bulkUserCard,
        isOpen,
      },
    });
  };

  handleLocationSelect = (val) => {
    this.setState({
      selectedLocation: val.locationId,
      selectedLocationName: val.locationName,
    });

    this.fetchUsers(val.locationId);
    this.fetchPath(val.locationId);
  };

  async componentWillReceiveProps(props) {
    if (this.state.selectedLocation === props.location) {
      return;
    }
    await this.setState({
      ...this.state,
      selectedLocation: props.location,
      selectedLocationName: props.locationName,
    });
    this.fetchUsers(this.state.selectedLocation);
    this.fetchPath(this.state.selectedLocation);
  }

  fetchPath = (loc) => {
    getPathFromLocation(loc).then((paths) => {
      paths = paths.map((path) => {
        return {
          id: path.id,
          name: path.name,
          selected: false,
          hiddenInPathways: path.hiddenInPathways,
        };
      });
      this.setState({ paths });
    });
  };

  fetchUsers = (loc) => {
    const { unleashProps } = this.props;
    this.setState({ dataLoading: true, showEmptyDataSourceMessage: false });
    let promise = null;
    let options = null;
    if (unleashProps.isFlagEnabled()) {
      options = {
        locationId: loc,
        userStatus: 'ACTIVE_OR_LAST_ACTIVE',
        roleIds: 19,
      };
      promise = getUsers(options);
    } else {
      options = {
        requestorId: 191439,
        requestorLocationId: loc,
        filterLocationTypeIds: [],
        filterRoleTypeIds: [19],
        filterLocationIds: [],
        filterUserStatus: 'all',
      };
      promise = listUsers(options);
    }
    promise
      .then((data) => {
        let learners = [];
        if (unleashProps.isFlagEnabled()) {
          learners = data;
        } else {
          learners = data.users;
        }
        learners = learners.map((user) => {
          return {
            ...user,
            tableData: { checked: false },
          };
        });
        setUsers(learners);
        this.USERS_COPY = learners;
        this.setState({ USERS: learners, dataLoading: false, showEmptyDataSourceMessage: true });
      })
      .catch(() => {
        this.setState({ USERS: [], dataLoading: false, showEmptyDataSourceMessage: true });
      });
  };

  closeBulkDisable = () => {
    this.setState({
      bulkDisableOpen: false,
    });
  };

  closeBulkDisableEntertainment = () => {
    this.setState({
      bulkDisableEntertainmentOpen: false,
    });
  };

  closeBulkDisableFnF = () => {
    this.setState({
      bulkDisableFnF: false,
    });
  };

  closeBulkPasswordEdit = () => {
    this.setState({
      bulkUserEditOpen: false,
    });
  };

  closeBulkLocationEdit = () => {
    this.setState({
      bulkUserLocationOpen: false,
    });
  };

  closeBulkServiceEdit = () => {
    this.setState({
      bulkUserServiceOpen: false,
    });
  };
  userEditClose = () => {
    this.setState({ userEditOpen: false });
  };

  showModal = () => {
    this.setState({ showInfo: true });
  };

  closeModal = () => {
    this.setState({ showInfo: false });
  };

  openPathAssignmentModal() {
    this.setState({ ...this.state, openPathAssignmentModal: true });
  }

  addLearnerGroupClose = () => {
    this.setState({ openLearnerGroup: false });
  };

  onClosePathAssignmentModal() {
    this.state.paths.forEach((path) => {
      path.selected = false;
    });
    this.state.USERS.map((user) => {
      this.state.deletedUsers.map((deletedUser) => {
        if (deletedUser === user.personId) {
          user.tableData.checked = false;
        }
      });
    });
    this.state.deletedUsers.map((deletedUser) => {
      const index = this.state.selectedUsersForPathAssignment.findIndex((user) => deletedUser === user.personId);
      this.state.selectedUsersForPathAssignment.splice(index, 1);
    });

    this.setState({
      ...this.state,
      openPathAssignmentModal: false,
      deletedUsers: [],
    });
  }

  handleResponseDialog = (isOpen) => {
    this.setState({
      response: {
        ...this.state.response,
        isOpen,
      },
    });
  };

  /**
   * @name getResponseBoxData
   * @param {array} successList
   * @param {array} errorList
   * @desc Prepares get response box data and returns results.
   * @return {array} responseData
   */
  getResponseBoxData = (successList, errorList) => {
    const type = this.state.manageBalance.type;
    const responseData = [];
    successList.forEach((_successNodes) => {
      responseData.push({
        statusCode: _successNodes.statusCode,
        text: `${_successNodes.transactionAmount} has been ${type === MANAGE_BALANCE_TYPE.ADD ? 'added to ' : 'deducted from '
          } ${_successNodes.userName}'s account.`,
      });
    });
    errorList.forEach((_errorNodes) => {
      responseData.push({
        statusCode: _errorNodes.statusCode,
        text: `${_errorNodes.statusMessage}.`,
      });
    });
    return responseData;
  };

  /**
   * @name submitManageBalance
   * @param {array} data
   * @desc Submits manage balance and check's in state whether its add / deduct balance process.
   * @return {void}
   */
  submitManageBalance = (data) => {
    const payload = {
      transactions: data.map((userBalanceMeta) => ({
        personId: userBalanceMeta.user.personId,
        featureName: null,
        transactionAmount: userBalanceMeta.amount,
        reason: userBalanceMeta.reason,
      })),
    };

    let requestHandler = null;
    const type = this.state.manageBalance.type;
    this.setGlobal({
      isLoading: true,
    });
    if (type === MANAGE_BALANCE_TYPE.ADD) {
      requestHandler = addBalance(payload);
    } else if (type === MANAGE_BALANCE_TYPE.DEDUCT) {
      requestHandler = deductBalance(payload);
    }
    requestHandler.then(
      (_successLog) => {
        this.setGlobal({
          isLoading: false,
        });
        this.setState({
          manageBalance: {
            ...this.state.manageBalance,
            isOpen: false,
          },
          response: {
            isOpen: true,
            data: this.getResponseBoxData(_successLog.successList, _successLog.errorList),
          },
        });
      },
      (_errorLog) => {
        this.setState({
          manageBalance: {
            ...this.state.manageBalance,
            isOpen: false,
          },
        });
        this.setGlobal({
          isLoading: false,
        });
        console.error('Something went wrong while adding/deducting balance, please check network.');
      }
    );
  };

  openConfirmationModal() {
    this.setState({ ...this.state, confirmationModal: true });
  }

  getUserStatus = (selectedUsers) => {
    const selectedUserFiltered = []; // CREATING NEW INSTANCE SO THAT ITS SHALLOW COPY DOESN'T GET IMPACTED
    this.setGlobal({
      isLoading: true,
    });
    return new Promise((resolve, reject) => {
      try {
        const userIds = selectedUsers.map((user) => user.personId);
        getUserFeatureStatus(userIds.join(',')).then((_successLog) => {
          selectedUsers.forEach((_user) => {
            const feature = _successLog.personFeatures.find(
              (feature) => _user.personId === feature.personId && feature.featureName === this._managementService.Play
            );
            if (feature) {
              selectedUserFiltered.push({
                ..._user,
                active: feature.status ? 'active' : 'inactive',
              });
            } else {
              selectedUserFiltered.push({
                ..._user,
                active: 'inactive',
              });
            }
          });
          resolve(selectedUserFiltered);
          this.setGlobal({
            isLoading: false,
          });
        });
      } catch (e) {
        console.warn('ERROR WHILE FETCHING USER STATUS');
        console.error(e);
        resolve(selectedUsers);
        this.setGlobal({
          isLoading: false,
        });
      }
    });
  };

  async closeConfirmationModal(isConfirmed) {
    await this.setState({ ...this.state, confirmationModal: false });
    if (isConfirmed) {
      this.openPathAssignmentModal();
    }
  }

  async onAddGroupClick(data) {
    const arr = Object.keys(data).map((key) => data[key]);
    this.setState({
      openLearnerGroup: true,
      selectedUser: await this.getUserStatus([...arr]),
    });
  }

  get getDatatableActions() {
    const actions = [
      {
        icon: forwardRef((props, ref) => (
          <CustomIcons
            icon={icons.GroupAdd}
            id="add-group-icon"
            style={{ color: CustomTheme.palette.purple.dark }}
            ref={ref}
            {...props}
          />
        )),
        tooltip: 'Create Learner Group',
        onClick: (event, rowData) => this.onAddGroupClick(rowData),
      },
      {
        icon: forwardRef((props, ref) => (
          <PeopleOutlineIcon style={{ color: CustomTheme.palette.purple.dark }} id="fnf-action-btn" {...props} ref={ref} />
        )),
        tooltip: 'Change Friends & Family Messaging Access',
        permissionName: 'Access_Friendsandfamily_Tab',
        onClick: async (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            bulkDisableFnF: true,
            selectedUser: await this.getUserStatus([...arr]),
          });
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <LocalActivityOutlinedIcon
            style={{ color: CustomTheme.palette.purple.dark }}
            id="entertainment-action-btn"
            {...props}
            ref={ref}
          />
        )),
        tooltip: 'Disable/Reactivate Entertainment Access',
        permissionName: 'Lock_Student_Out_Of_Entertainment_Center',
        onClick: async (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            bulkDisableEntertainmentOpen: true,
            selectedUser: await this.getUserStatus([...arr]),
          });
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <AccountBoxIcon style={{ color: CustomTheme.palette.purple.dark }} id="usercard-action-btn" {...props} ref={ref} />
        )),
        tooltip: 'Learner Card',
        onClick: (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            selectedUser: arr,
          });
          this.handleBulkUserCardDialog(true);
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <LockOpenIcon
            style={{ color: CustomTheme.palette.purple.dark }}
            id="change-password-action-btn"
            {...props}
            ref={ref}
          />
        )),
        tooltip: 'Change Password',
        permissionName: 'Reset_Learner_Password',
        onClick: (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            bulkUserEditOpen: true,
            selectedUser: arr,
          });
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <HowToRegIcon
            style={{ color: CustomTheme.palette.purple.dark }}
            id="user-status-action-btn"
            {...props}
            ref={ref}
          />
        )),
        tooltip: 'Disable/Reactivate Learners',
        permissionName: 'Inactivate_Learners',
        onClick: (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            bulkDisableOpen: true,
            selectedUser: arr,
          });
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <LocationOnIcon style={{ color: CustomTheme.palette.purple.dark }} id="location-action-btn" {...props} ref={ref} />
        )),
        tooltip: 'Change Location',
        permissionName: 'Move_Learner_From_One_Location_To_Another',
        onClick: (event, rowData) => {
          var arr = Object.keys(rowData).map((key) => rowData[key]);
          this.setState({
            bulkUserLocationOpen: true,
            selectedUser: arr,
          });
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <AssignmentReturnIcon
            style={{ color: CustomTheme.palette.purple.dark }}
            data-cy-name="assignee-pathway-btn"
            {...props}
            ref={ref}
          />
        )),
        tooltip: 'Assign Content',
        permissionName: 'Assign_Path_To_Learner',
        id: 'assignee-pathway-btn',
        onClick: (event, rowData) => {
          this.setState(
            {
              assignmentType: ASSIGNMENT_TYPE.ASSIGNE,
            },
            () => {
              if (this.state.selectedUsersForPathAssignment.length > 1) {
                this.openConfirmationModal();
                return;
              }
              this.openPathAssignmentModal();
            }
          );
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <LayersClearIcon
            style={{ color: CustomTheme.palette.purple.dark }}
            id="unassign-path-action-btn"
            {...props}
            ref={ref}
          />
        )),
        tooltip: 'Unassign Content',
        permissionName: 'Unassign_Path_To_Learner',
        id: 'un-assignee-pathway-btn',
        onClick: (event, rowData) => {
          this.setState(
            {
              assignmentType: ASSIGNMENT_TYPE.UNASSIGNE,
            },
            () => {
              if (this.state.selectedUsersForPathAssignment.length > 1) {
                this.openConfirmationModal();
                return;
              }
              this.openPathAssignmentModal();
            }
          );
        },
      },
      {
        icon: forwardRef((props, ref) => (
          <DeleteIcon style={{ color: CustomTheme.palette.purple.dark }} id="delete-path-action-btn" {...props} ref={ref} />
        )),
        icon: DeleteIcon,
        tooltip: 'Delete Path',
        permissionName: 'Delete_Path_From_Learner',
        id: 'delete-pathway-btn',
        onClick: (event, rowData) => {
          this.setState(
            {
              assignmentType: ASSIGNMENT_TYPE.DELETE,
            },
            () => {
              if (this.state.selectedUsersForPathAssignment.length > 1) {
                this.openConfirmationModal();
                return;
              }
              this.openPathAssignmentModal();
            }
          );
        },
      },
    ];
    if ((this.global.cbacConfigFlag || !this.global.isAttorney) && this.state.incentiveEngineVisibile) {
      if (
        isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Grant_Tokens') ||
        isPermitted(this.global.cbacConfigFlag, this.global.permissions, 'Remove_Tokens')
      ) {
        actions.push({
          icon: forwardRef((props, ref) => (
            <StarsIcon
              style={{ color: CustomTheme.palette.purple.dark }}
              id="manage-token-action-btn"
              {...props}
              ref={ref}
            />
          )),
          tooltip: 'Add Token',
          id: 'add-token-btn',
          onClick: (event, rowData) => {
            this.handleBalanceDialog(true, MANAGE_BALANCE_TYPE.ADD, rowData);
          },
        });
      }
    }

    const unleashClient = this.props.unleashClient();

    const FARegisteredUsers = this.state.selectedUsersForPathAssignment.filter((item) => item.frstatus === 'Registered');
    const usersToReset = this.state.selectedUsersForPathAssignment;
    if (usersToReset.length) {
      actions.unshift({
        icon: forwardRef((props, ref) => resetFAIcon),
        tooltip: 'Reset Biometric Authentication',
        id: 'reset_fa',
        onClick: async (event, rowData) => {
          this.setState({
            openResetFaceAuthConfirmation: true,
            resetFaceAuthUsers: usersToReset,
          });
        },
      });
    }
    let permittedActions = [];
    for (let action of actions) {
      if (
        !action.permissionName ||
        isPermitted(this.global.cbacConfigFlag, this.global.permissions, action.permissionName)
      ) {
        permittedActions = [...permittedActions, action];
      }
    }
    return permittedActions;
  }

  /**
   * @name toggleManageBalanceState
   * @desc Toggle's state from add / deduct.
   * @return {void}
   */
  toggleManageBalanceState = () => {
    const type = this.state.manageBalance.type;
    if (type === MANAGE_BALANCE_TYPE.ADD) {
      this.handleBalanceDialog(true, MANAGE_BALANCE_TYPE.DEDUCT);
    } else if (type === MANAGE_BALANCE_TYPE.DEDUCT) {
      this.handleBalanceDialog(true, MANAGE_BALANCE_TYPE.ADD);
    }
  };

  handleBalanceDialog = (isOpen, type, selectedUser = this.state.manageBalance.formData) => {
    let manageBalance = {};
    if (isOpen && type === MANAGE_BALANCE_TYPE.ADD) {
      // ADD & OPEN
      manageBalance = {
        ...this.state.manageBalance,
        isOpen: true,
        title: 'Add Token',
        amountLabel: 'Amount to Add',
        reasonLabel: 'Reason',
        type: MANAGE_BALANCE_TYPE.ADD,
        formData: selectedUser.map((user) => ({
          user,
          amount: '',
          reason: '',
        })),
      };
    } else if (isOpen && type === MANAGE_BALANCE_TYPE.DEDUCT) {
      // DEDUCT
      manageBalance = {
        ...this.state.manageBalance,
        isOpen: true,
        title: 'Deduct Token',
        amountLabel: 'Amount to Deduct',
        reasonLabel: 'Reason',
        type: MANAGE_BALANCE_TYPE.DEDUCT,
        formData: selectedUser.map((user) => ({
          user,
          amount: '',
          reason: '',
        })),
      };
    } else {
      manageBalance = {
        ...this.state.manageBalance,
        isOpen,
      };
    }

    this.setState({
      manageBalance,
    });
  };

  closeFRRequestConfirm = () => {
    this.setState({
      isFRRequestConfirmOpen: false,
      fRRequestPersonId: null,
      fRRequestId: null,
    });
  };
  /*
   * @name selectData
   * @param {string} type
   * @desc A method used to select datatable data based upon type.
   * @return {void}
   */
  selectData = (type) => {
    const USERS = this.tableRef.dataManager.sortedData;
    let data = [];
    // RESETTING TO ALL
    // UN-SELECTION
    USERS.map((_user) => {
      _user.tableData.checked = false;
      return _user;
    });
    switch (type) {
      case 'page':
        data = this.tableRef.dataManager.pagedData;
        break;
      default:
        data = this.tableRef.dataManager.sortedData;
    }
    data.forEach((user) => {
      const userFindIndex = USERS.findIndex((_users) => _users.tableData.id === user.tableData.id);
      USERS[userFindIndex].tableData.checked = true;
    });

    const selectedUsersForPathAssignment = USERS.filter((user) => user.tableData.checked);
    this.setState({ USERS, selectedUsersForPathAssignment });
  };

  openFRRequestConfirm = (e) => {
    const { currentTarget } = e;
    this.setState({
      isFRRequestConfirmOpen: true,
      fRRequestPersonId: currentTarget.getAttribute('data-person-id'),
      fRRequestId: currentTarget.getAttribute('data-request-id'),
    });
  };

  onFRRequestConfirm = async () => {
    try {
      const personId = this.state.fRRequestPersonId;
      const requestId = this.state.fRRequestId;
      this.setState({
        isFRRequestConfirmOpen: false,
        fRRequestPersonId: null,
        fRRequestId: null,
      });
      await approveFRRegistrationRequest(requestId, personId);
      this.fetchUsers(this.state.selectedLocation);
    } catch {
      this.setState({
        showErrorMsg: true,
        errorMsg: `FR Re-Registration Approve Request Failed.`,
      });
    }
  };

  handleCloseErrorMsg = () => {
    this.setState({
      showErrorMsg: false,
      errorMsg: '',
    });
  };

  handleCloseSnackbar = () => {
    this.setState({
      snackbarOpen: false,
      snackBarMessage: '',
    });
  };

  closeResponseStatusDialog = () => {
    this.setState({
      responseData: {
        isOpen: false,
        title: '',
        status: '',
        message: '',
      },
    });
  };

  closeResetFaceAuthConfirmation = () => {
    this.setState({ openResetFaceAuthConfirmation: false });
  };

  getStatus = (user) => {
    if (user.hasFaceAuthData && user.faceAuthDeleted) {
      return 'success';
    } else {
      return 'failed';
    }
  };

  resetFaceAuth = (resetFaceAuthUsers) => {
    this.setGlobal({ isLoading: true });
    const usernames = (resetFaceAuthUsers || this.state.resetFaceAuthUsers).map((item) => item.userName);
    let putBody = {
      requesterId: USER_CONTEXT.personId,
      locationId: this.state.selectedLocation,
      usernames,
    };

    resetFacialAuthentication(putBody)
      .then((response) => {
        this.setGlobal({ isLoading: false });
        const data = response.payload.map((user) => ({
          username: user.username,
          status: this.getStatus(user),
        }));
        let USERS = [...this.state.USERS].map((item) => {
          if (usernames.includes(item.userName)) {
            item.frstatus = 'Not Registered';
          }
          return item;
        });

        const successUsers = data.filter((user) => user.status === 'success');
        const failedUsers = data.filter((user) => user.status === 'failed');
        this.setState({ openResetFaceAuthConfirmation: false });
        if (data.length === failedUsers.length) {
          this.setState({
            snackbarOpen: true,
            snackBarMessage: `Failed to reset biometric authentication.`,
            snackBarType: 'error',
          });
        } else if (data.length === successUsers.length) {
          this.setState({
            snackbarOpen: true,
            snackBarMessage: `Biometric Authentication has been reset successfully!`,
            snackBarType: 'success',
          });
        } else {
          this.setState({
            resetBioAuthDialog: {
              isOpen: true,
              successfulUsers: successUsers,
              failedUsers,
            },
            USERS,
          });
        }

      })
      .catch((err) => {
        this.setGlobal({ isLoading: false });
        this.setState({
          responseData: {
            isOpen: true,
            title: 'Failure!',
            status: 'Error',
            message: 'Failed to reset Biometric Authentication!',
          },
          openResetFaceAuthConfirmation: false,
        });
      });
  };

  onRowsSelected = (rows) => {
    this.setState({ selectedUsersForPathAssignment: rows }, () => {
      const toolbar = document.querySelector(`[class^="MTableToolbar-actions-"]`);
      if (toolbar) {
        const buttons = toolbar.querySelectorAll('button');
        buttons.forEach((button, index) => {
          button.id = convertToID(button.title);
        });
      }
    });
  };

  onCloseResetBioAuthModal = () => {
    this.setState({ resetBioAuthDialog: { ...this.state.resetBioAuthDialog, isOpen: false } });
  };

  render() {
    return (
      <Suspense fallback={<Loader isLoading={true} />}>
        <Loader isLoading={this.global.isLoading} />
        <div style={{ padding: '1.5rem', width: '100%' }}>
          <Grid container spacing={3}>
            <Grid item container>
              <ConfirmationModal
                isOpen={this.state.isFRRequestConfirmOpen}
                onClose={this.closeFRRequestConfirm}
                onContinue={this.onFRRequestConfirm}
                continueText="Yes"
                cancelText="No"
                heading={`Do you want to trigger the Biometric Authentication process again for this learner?`}
              />

              <ConfirmationModal
                isOpen={this.state.openResetFaceAuthConfirmation}
                onClose={this.closeResetFaceAuthConfirmation}
                onContinue={this.resetFaceAuth}
                continueText="Yes"
                cancelText="No"
                heading={`Are you sure you want to reset the Biometric Authentication for selected user(s)?`}
              />

              <ResetBioauthModal {...this.state.resetBioAuthDialog} resetFaceAuth={this.resetFaceAuth}
                onClose={this.onCloseResetBioAuthModal}
              />
              <ResponseStatusModal
                isOpen={this.state.responseData.isOpen}
                onClose={this.closeResponseStatusDialog}
                title={this.state.responseData.title}
                type={this.state.responseData.status}
                description={this.state.responseData.message}
              />

              <Snackbar
                open={this.state.showErrorMsg}
                autoHideDuration={6000}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                onClose={this.handleCloseErrorMsg}
              >
                <MuiAlert severity="error" elevation={6} variant="filled">
                  {this.state.errorMsg}
                </MuiAlert>
              </Snackbar>
              <Zoom in unmountOnExit>
                <Grid container spacing={3}>
                  <PathAssginmentToUser
                    type={this.state.assignmentType}
                    open={this.state.openPathAssignmentModal}
                    onClose={this.onClosePathAssignmentModal}
                    locationId={this.state.selectedLocation}
                    paths={this.state.paths}
                    selectedUser={this.state.selectedUsersForPathAssignment.slice()}
                    showSubHeader={true}
                    deletedUsers={this.state.deletedUsers}
                  />
                  <PathAssignmentConfirmationModal
                    type={this.state.assignmentType}
                    open={this.state.confirmationModal}
                    onClose={this.closeConfirmationModal}
                  />
                  <PermissionControl
                    permissionName="Add_Learner"
                    forceRestriction={!this.state.selectedLocation || this.state.selectedLocation === this.NO_LOCATION}
                  >
                    <Grid item sm={6} xs={12}>
                      <Box display="flex" flexDirection="row">
                        <Box marginRight="10px">
                          <CreateUserDialog
                            fetchUsers={() => this.fetchUsers(this.props.location)}
                            isSuperAdmin={this.global.isSuperAdmin}
                          />
                        </Box>
                        <Box>
                          <BulkUserDialog
                            myLocation={this.state.selectedLocation}
                            myLocationName={this.state.selectedLocationName}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </PermissionControl>
                  {this.state.selectedLocation && this.state.USERS && (
                    <Grid item xs={12} sm={this.global.isTeacher ? 12 : 6}>
                      <Box display="flex" flexDirection="row" justifyContent="flex-end" width="100%">
                        <Paper
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 60,
                            margin: 0,
                            marginBottom: '-38px',
                            marginRight: '10px',
                            height: 36,
                          }}
                        >
                          <Tooltip title="refresh users">
                            <IconButton
                              onClick={() => {
                                this.fetchUsers(this.state.selectedLocation);
                                this.fetchPath(this.state.selectedLocation);
                              }}
                              color="primary"
                              size="small"
                            >
                              <RefreshIcon />
                            </IconButton>
                          </Tooltip>
                        </Paper>
                        <Box display="flex" flexDirection="row">
                          <Box marginRight="10px">
                            <Button
                              onClick={() => this.selectData('all')}
                              variant="contained"
                              color="primary"
                              disableElevation
                            >
                              Select All
                            </Button>
                          </Box>
                          <Box>
                            <Button
                              onClick={() => this.selectData('page')}
                              variant="contained"
                              color="primary"
                              disableElevation
                            >
                              Select This Page
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Zoom>
              <ManageBalance
                {...this.state.manageBalance}
                toggleAction={() => this.toggleManageBalanceState()}
                onClose={() => this.handleBalanceDialog(false)}
                onSubmit={(data) => this.submitManageBalance(data)}
              />
              <ResponseBox
                heading={this.state.response.heading}
                isOpen={this.state.response.isOpen}
                onClose={() => this.handleResponseDialog(false)}
                data={this.state.response.data}
                groupedView={false}
              />
              <Loader isLoading={this.state.dataLoading} />
            </Grid>
            {this.state.selectedLocation && this.state.USERS ? (
              <Grid item container>
                <Zoom style={{ width: '100%' }} in unmountOnExit>
                  <div>
                    <Datatable
                      style={{ width: '100%' }}
                      tableRef={(ref) => (this.tableRef = ref)}
                      onFilterChange={() => this.setState({ USERS: this.USERS_COPY })}
                      onOrderChange={this.setOrdering}
                      onChangePage={(pageNo) => this.setState({ pageNo })}
                      onChangeRowsPerPage={(rowsPerPage) => this.setState({ rowsPerPage })}
                      title={`${this.state.selectedLocationName} Learners`}
                      data={this.state.USERS}
                      columns={this.columns1}
                      icons={{
                        Search: SearchIcon,
                        ResetSearch: CloseIcon,
                        FirstPage: FirstPageIcon,
                        LastPage: LastPageIcon,
                        NextPage: NavigateNextIcon,
                        PreviousPage: NavigateBeforeIcon,
                        Filter: FilterListIcon,
                        SortArrow: ArrowDownwardIcon,
                        Export: SaveAltIcon,
                        Edit: EditIcon,
                        User: <UserIcon />,
                      }}
                      components={{
                        Toolbar: (props) => (
                          <div>
                            <MTableToolbar {...props} />
                            {/* <div style={{padding: '0px 10px'}}>
                          <Paper style={{height: 50, marginBottom: 12, width: 260, display: 'flex', alignItems: 'center'}}>
                            <Tooltip title="location info">
                              <IconButton disabled color="primary" size="small" onClick={this.showModal} style={{marginLeft: 12, marginRight:12}} >
                                <BookIcon/>
                              </IconButton>
                            </Tooltip>
                          </Paper>
                        </div> */}
                          </div>
                        ),
                      }}
                      options={{
                        showEmptyDataSourceMessage: this.state.showEmptyDataSourceMessage,
                        search: false,
                        sorting: true,
                        pageSize: this.state.rowsPerPage,
                        selection: true,
                        showSelectAllCheckbox: true,
                        filtering: true,
                        exportButton: true,
                        actionsColumnIndex: 0,
                        pageSizeOptions: [20, 50, 100],
                        exportAllData: true,
                        exportCsv: (columns, data) =>
                          this.exportUsersList(columns, data, `${this.state.selectedLocationName} Learners`),
                        rowStyle: (rowData) => ({
                          backgroundColor: rowData.active === 'active' ? '#ffffff' : '#e8e8e8',
                        }),
                        selectionProps: (rowData) => ({
                          id: `checkbox-${rowData.userName}`,
                        }),
                      }}
                      actions={this.getDatatableActions}
                      onSelectionChange={(rows) => this.onRowsSelected(rows)}
                    />
                    <Dialog open={this.state.showInfo} onClose={this.closeModal} style={{ padding: 12 }}>
                      <h3 style={{ textAlign: 'center' }}>{this.state.selectedLocationName}</h3>
                      <div style={{ padding: '.5rem' }}>
                        n<h5>Services</h5>
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell>Service Name</TableCell>
                              <TableCell align="right">Auto-Provisioned</TableCell>
                              <TableCell align="right">SSO Enabled</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rows.map((row) => (
                              <TableRow key={row.name}>
                                <TableCell component="th" scope="row">
                                  {row.name}
                                </TableCell>
                                <TableCell align="right">{row.provision}</TableCell>
                                <TableCell align="right">{row.sso}</TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </div>
                    </Dialog>
                  </div>
                </Zoom>
                {this.state.selectedUser ? (
                  <div>
                    <BulkDisable
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkDisableOpen}
                      close={this.closeBulkDisable}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkDisableEntertainment
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkDisableEntertainmentOpen}
                      close={this.closeBulkDisableEntertainment}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkDisableFriendAndFamily
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkDisableFnF}
                      close={this.closeBulkDisableFnF}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkEditPassword
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkUserEditOpen}
                      close={this.closeBulkPasswordEdit}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkEditLocation
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkUserLocationOpen}
                      close={this.closeBulkLocationEdit}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkServiceProvision
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      open={this.state.bulkUserServiceOpen}
                      close={this.closeBulkServiceEdit}
                      users={this.state.selectedUser}
                      requestorId={USER_CONTEXT.personId}
                      requestorLocationId={this.state.selectedLocation}
                      requestorLocationName={this.state.selectedLocationName}
                    />
                    <BulkUserCard
                      isOpen={this.state.bulkUserCard.isOpen}
                      onClose={() => this.handleBulkUserCardDialog(false)}
                      setResponse={(response) =>
                        this.setState({
                          response,
                        })
                      }
                      fetchUsers={() => this.fetchUsers(this.props.location)}
                      users={this.state.selectedUser}
                    />
                  </div>
                ) : null}

                <NewUserEdit
                  fetchUsers={() => this.fetchUsers(this.props.location)}
                  open={this.state.userEditOpen}
                  close={this.userEditClose}
                  user={this.state.selectedUser}
                  requestorId={USER_CONTEXT.personId}
                  requestorLocationId={this.state.selectedLocation}
                  requestorLocationName={this.state.selectedLocationName}
                />

                <AddNewLearnerGroupDialog
                  open={this.state.openLearnerGroup}
                  handleClose={() => {
                    this.addLearnerGroupClose();
                  }}
                  preSelectedLearners={this.state.selectedUser.map((user) => ({ ...user, reachable: true }))}
                />

                <Snackbar
                  open={this.state.snackbarOpen}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  autoHideDuration={6000}
                  onClose={this.handleCloseSnackbar}
                >
                  <SnackBar isShown type={this.state.snackBarType}>
                    {this.state.snackBarMessage}
                  </SnackBar>
                </Snackbar>

                {/*<UserEdit open={this.state.userEditOpen} close={this.userEditClose} user={this.state.selectedUser} requestorLocationId={this.state.selectedLocation} requestorLocationName={this.state.selectedLocationName}/> */}
              </Grid>
            ) : null}
          </Grid>
        </div>
      </Suspense>
    );
  }
}

export default withUnleashClient(withUnleash(Users, UnleashService.FLAGS.OPTIMIZE_USER_LISTING_ENDPOINT));
