export default theme  => ({
    voiceIDPlayIcon:{
        color: "#0F48B8",
        flexDirection: "column",
        justifyContent: "center",
        padding: "8px",
        alignItems: "center",
        borderRadius:" 50%",
        boxShadow: "0 0 0 1px #0F48B8",
      },
    
      playIcon: {
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      },

      videoPlayer: {
        width: "60vh",
        height: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "12px",
      },

      reRecordBox: {
        display: "flex",
        justifyContent: "center",
      },

      reRecordButton: {
        backgroundColor: "#fff",
        color: "#0F48B8",
        borderColor: "#0F48B8",
      },
});